import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { MapPlotData, RegionsTooltip } from "../../types/type";
import Box from "../common/Box";
import Loader from "../common/Loader";
import { Tab, Tabs } from "../common/Tabs";
import Typography from "../common/Typography";
import WorldMap from "../common/WorldMap";
import GeoDataStoreCard from "./GeoDataStoreCard";
import { regions } from "../maps/aws_regions";
import { isContainsValue } from "../../utils/commonUtils";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  fetchDatastoreRegionsSlice,
  fetchAIRegionsSlice,
  fetchAllRegionsDataSlice,
  fetchFrameworkRegionsSlice,
} from "../../redux/slices/dashboardSlice";

interface AllRegionsProp {
  sensitive_data: Array<{
    records: number;
    datastores: number;
    count: number;
    tag: string;
    tagType: string;
  }>;
  sensitive_datastore: number;
  total_datastore: number;
}

const GeoDistributionCard = () => {
  const [activeTab, setActiveTab] = useState("inventory-view");
  const [isFetchingGeoDistribution, setIsFetchingGeoDistribution] =
    useState(false);
  const { aiSummary } = useContext(AppContext);

  const [isFetchingAllRegionsOverview, setIsFetchingAllRegionsOverview] =
    useState(false);
  const [activeRegion, setActiveRegion] = useState<{
    data: [];
    region: string;
    regionCode: string;
  }>({
    data: [],
    region: "All Regions",
    regionCode: "all",
  });
  const [geoData, setGeoData] = useState<
    Array<{ type: string; data: Array<MapPlotData> }>
  >([{ type: "", data: [] }]);
  const [tooltipData, setTooltipData] = useState<RegionsTooltip>({
    impacted: 0,
    cloud: "",
    data: [],
  });
  const [overviewAll, setOverviewAll] = useState<any>([]);
  const [overviewAllRegions, setOverviewAllRegions] = useState<AllRegionsProp>({
    sensitive_data: [],
    sensitive_datastore: 0,
    total_datastore: 0,
  });
  const [overviewLLMData, setOverviewLLMData] = useState<any>([]);
  const [impactedDataStore, setImpactedDataStore] = useState<
    number | undefined
  >(0);
  const [totalDataStore, setTotalDataStore] = useState(0);
  const [geoView, setGeoView] = useState<string>("frameworks");
  const dispatch = useDispatch();
  const isFrameworkView = geoView === "frameworks";

  const theme = useTheme();
  const styles = {
    geoCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      position: "relative",
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    cardContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "65% 35%",
    },
    mapContainer: {
      width: "100%",
    },
    mapTabs: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    mapLegends: {
      width: "fit-content",
    },
    cardHeading: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  };

  const cardTabs = [
    {
      value: "inventory-view",
      label: "Inventory View",
      color: theme.palette.lightMagenta.main,
      stroke: theme.palette.purpleStroke.main,
    },
    //{
    //  value: 'risk-view',
    //  label: 'Risk View',
    //  color: theme.palette.critical.main,
    //  stroke: theme.palette.redStroke.main,
    //},
  ];

  const getSensitiveData = async (param) => {
    setIsFetchingAllRegionsOverview(true);
    const payload = {
      customerId: getCustomerId(),
      ...param,
    };
    const resp = await dispatch(fetchAllRegionsDataSlice(payload));
    if (resp.payload) {
      setOverviewAllRegions(resp.payload);
    } else {
      setOverviewAllRegions({} as AllRegionsProp);
    }
    setIsFetchingAllRegionsOverview(false);
  };

  const setGeoDataStoreCardData = (allData) => {
    const formattedData = formatDataForMap(
      allData?.filter((item) => item.region !== "all")
    );
    let inventoryView = formattedData;
    const riskView = formattedData.filter(
      (data) => data.properties.hasDataStoreRisk
    );
    return { inventoryView: inventoryView, riskView: riskView };
  };

  const getGeoDistributionData = async () => {
    setIsFetchingGeoDistribution(true);
    const payload = {
      customerId: getCustomerId(),
    };
    let fetchData;
    if (geoView === "llms") {
      fetchData = dispatch(fetchAIRegionsSlice(payload));
    } else {
      fetchData = dispatch(fetchFrameworkRegionsSlice(payload));
    }
    let resp = await fetchData;
    if (resp && resp?.payload) {
      let formattedData;

      setOverviewLLMData(resp.payload);
      formattedData = setGeoDataStoreCardData(resp?.payload?.data);

      setGeoData([
        { type: "inventory-view", data: formattedData?.inventoryView },
        {
          type: "risk-view",
          data: formattedData?.riskView,
        },
      ]);
    } else {
      setGeoData([{ type: "", data: [] }]);
    }
    setIsFetchingGeoDistribution(false);
  };

  useEffect(() => {
    getGeoDistributionData();
  }, [isFrameworkView]);

  const getImpactedDSCount = () => {
    return overviewAllRegions?.sensitive_datastore;
    //return activeRegion?.regionCode === 'all'
    //  ? overviewAllRegions?.sensitive_datastore || 0
    //  : overviewRegionSpecific?.reduce(
    //      (
    //        acc: number,
    //        curr: {
    //          dataType: string;
    //          numberOfRecords: number;
    //          totalValue: number;
    //          impacted: number;
    //        }
    //      ) => acc + curr.impacted,
    //      0
    //    );
  };

  // helps to build sensitive data
  const buildSensitiveData = (isLLM, dataItem) => {
    if (isLLM) {
      return dataItem?.sensitiveData;
    }
    return [
      {
        tag: "Prompts",
        records: dataItem?.promptCount,
      },
      {
        tag: "Context",
        records: dataItem?.contextCount,
      },
      {
        tag: "Response",
        records: dataItem?.responseCount,
      },
    ];
  };

  const formatDataForMap = (data) => {
    return data.map((dataItem) => {
      const regionData = regions.find(
        (region) => region.code === dataItem?.region
      );
      return {
        type: "Feature",
        properties: {
          id: dataItem.region,
          region: dataItem?.region,
          name: regionData?.name,
          impacted: !isFrameworkView ? dataItem?.llms : dataItem?.frameworks,
          hasDataResidencyViolation: dataItem?.hasDataResidencyViolation,
          hasDataStoreRisk: dataItem?.hasDataStoreRisk,
          sensitiveData: buildSensitiveData(!isFrameworkView, dataItem),
          cloud: dataItem?.cloud,
        },
        geometry: {
          type: "Circle",
          coordinates: [regionData?.longitude, regionData?.latitude],
        },
      };
    });
  };

  const totalLLMs = isContainsValue(aiSummary?.llm_ds_type)
    ? Object.values(aiSummary?.llm_ds_type)?.reduce(
        (acc: number, curr: any) => {
          return acc + curr;
        },
        0
      )
    : 0;

  // api changed that's why functions and conditions changed
  useEffect(() => {
    if (isContainsValue(overviewAllRegions) && !isFrameworkView) {
      const totalRecords = overviewAllRegions?.sensitive_data?.reduce(
        (acc: number, curr) => acc + curr.count,
        0
      );
      const overviewAll = overviewAllRegions?.sensitive_data?.map((data) => ({
        dataType: data?.tag,
        numberOfRecords: data?.count || data?.records || 0,
        impacted: data?.datastores,
        totalValue: totalRecords,
      }));
      setOverviewAll(overviewAll);

      const selectedRegion = overviewLLMData?.data?.find(
        (item) => item.region === "all"
      );
      const impactedValue = isFrameworkView
        ? selectedRegion?.frameworks
        : selectedRegion?.llms;
      const totalImpacted = overviewLLMData?.totalCount;
      setImpactedDataStore(impactedValue);
      setTotalDataStore(totalImpacted);
    } else if (!isFrameworkView) {
      setOverviewAll([]);
    }
  }, [overviewAllRegions, geoData]);

  // api changed that's why functions and conditions changed
  useEffect(() => {
    if (activeRegion?.regionCode === "all") {
      const selectedData = overviewLLMData?.data?.find(
        (item) => item?.region === activeRegion?.regionCode
      );
      setOverviewAll(buildSensitiveData(!isFrameworkView, selectedData));
      setImpactedDataStore(selectedData?.frameworks);
      setTotalDataStore(overviewLLMData.totalCount);
    } else {
      const selectedData = geoData[0]?.data?.find(
        (item) => item?.properties?.region === activeRegion?.regionCode
      )?.properties;
      setOverviewAll(selectedData?.sensitiveData);
      setImpactedDataStore(selectedData?.impacted);
      setTotalDataStore(overviewLLMData.frameworks);
    }
  }, [activeRegion?.regionCode, overviewLLMData]);

  // useEffect(() => {
  //   if (isContainsValue(overviewForSelectedRegion)) {
  //     const totalRecords = overviewForSelectedRegion?.sensitive_data?.reduce(
  //       (acc: number, curr) => acc + curr.count,
  //       0
  //     );
  //     setOverviewRegionSpecific(
  //       overviewForSelectedRegion?.sensitive_data?.map((data) => ({
  //         dataType: data?.tag,
  //         numberOfRecords: data?.count,
  //         impacted: data?.datastores,
  //         totalValue: totalRecords,
  //       }))
  //     );
  //     setImpactedDataStore(overviewForSelectedRegion?.sensitive_datastore);
  //     setTotalDataStore(overviewForSelectedRegion?.total_datastore);
  //   }
  // }, [overviewForSelectedRegion]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setActiveRegion({ data: [], region: "All Regions", regionCode: "all" });
  };

  const handleMouseOverTooltip = (cloud, data, impacted) => {
    if (tooltipData?.data?.length === 0) {
      const recordsForTooltip =
        data?.map(({ tag, records }) => ({
          dataType: tag,
          records: records,
        })) || [];
      setTooltipData({ impacted: impacted, data: recordsForTooltip, cloud });
    }
  };

  const tabData = [
    {
      value: "frameworks",
      label: "Frameworks",
    },
    {
      value: "llms",
      label: "LLMs",
    },
  ];

  return (
    <Box sx={styles.geoCard}>
      {isFetchingGeoDistribution ? (
        <Loader height="531px" />
      ) : (
        <>
          <Box sx={styles.cardHeading}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              Geo distribution
            </Typography>

            <Tabs
              value={geoView}
              onChange={(event, newValue) => {
                setGeoView(newValue);
              }}
            >
              {tabData?.map((item) => (
                <Tab value={item.value} label={item.label} key={item.value} />
              ))}
            </Tabs>
          </Box>
          <Box sx={styles.cardContent}>
            <Box sx={styles.mapTabs}>
              <WorldMap
                countriesToPlot={
                  geoData.find((data) => data.type === activeTab)?.data || []
                }
                activeTabData={
                  cardTabs.find((tab) => tab.value === activeTab) || cardTabs[0]
                }
                activeRegion={activeRegion}
                setActiveRegion={setActiveRegion}
                handleMouseOverTooltip={handleMouseOverTooltip}
                tooltipData={tooltipData || []}
                clearTooltipData={() =>
                  setTooltipData({
                    impacted: 0,
                    cloud: "",
                    data: [],
                  })
                }
                isFrameworkView={isFrameworkView}
              />
            </Box>
            <GeoDataStoreCard
              barColor={
                cardTabs.find((tab) => tab?.value === activeTab)?.color || ""
              }
              impactedDS={impactedDataStore}
              totalDS={totalDataStore}
              activeRegion={activeRegion}
              dataDistribution={overviewAll}
              isFetchingData={isFetchingAllRegionsOverview}
              isFrameworkView={isFrameworkView}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default GeoDistributionCard;
