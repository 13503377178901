import ConstructionIcon from "@mui/icons-material/Construction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import {
  listPoliciesAsync,
  deletePolicyAsync,
} from "../../redux/slices/policySlice";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Loader from "../common/Loader";
import NoData from "../common/NoData";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { TableSearchProp } from "../../types/type";
import { useHistory } from "react-router-dom";
import CompliancePolicyModal from "../modal/compliance-policy-modal/CompliancePolicyModal";
import { getAllPolicies } from "../../redux/slices/policySlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { listDataStoreNames } from "../../redux/slices/dataStoreSlice";
import {
  fetchLLMPolicyViolationsSlice,
  fetchPolicyViolationsSlice,
} from "../../redux/slices/dashboardSlice";
import { AddPolicy } from "../policy/AddPolicy";

interface PolicyStoreTableProp {
  onSearch: TableSearchProp;
}

const PolicyStoreTable = ({ onSearch }: PolicyStoreTableProp) => {
  const {
    drawerData,
    // policyStoreDetails,
    selectedPolicy,
    setSelectedPolicy,
    // isFetchingPolicyStoreDetails,
    // getPolicyDetails,
    modifyPolicyDetails,
    isFetchingModifyPolicyDetails,
    getModifyPolicyDetails,
  } = useContext(AppContext);

  const policyData = useSelector(
    (state: any) => state.dashboard.policyViolationList
  );
  const [selectedPolicyCategoryPolicies, setSelectedPolicyCategoryPolicies] =
    useState<
      Array<{ dsCount: number; policyType: string; id: string; title: string }>
    >([{ dsCount: 0, policyType: "", id: "", title: "" }]);

  const [mainData, setMainData] = useState<
    Array<{ dsCount: number; policyType: string; id: string; title: string }>
  >([{ dsCount: 0, policyType: "", id: "", title: "" }]);

  const [isEditPolicyModalOpen, setIsEditPolicyModalOpen] = useState(false);
  const [policiesList, setPoliciesList] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [selectedAccountIDs, setSelectedAccountIDs] = useState();
  const [alertingPolicies, setAlertingPolicies] = useState<Array<any>>([]);
  const [compliancePolicies, setCompliancePolicies] = useState<Array<any>>([]);
  const [datastoreMappings, setDatastoreMappings] = useState({});
  const [policyId, setPolicyId] = useState("");
  const [policyInformationList, setPolicyInformationList] = useState([]);
  const [modifyPolicyData, setModifyPolicyData] = useState<{
    id: string;
    policyCfgId: string;
  }>({ id: "", policyCfgId: "" });
  const [policyStoreDetails, setPolicyStoreDetails] = useState<Array<any>>([]);
  const [isFetchingPolicyStoreDetails, setIsFetchingPolicyStoreDetails] =
    useState(false);

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const styles = {
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: theme.spacing(1.5),
      zIndex: 0,
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    moreIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
    },
  };

  const fetchPolicies = async () => {
    const res = await dispatch(listPoliciesAsync());
    if (res.payload) {
      setPolicyInformationList(res.payload);
    }
  };

  const getDatastoreNames = async (ids) => {
    let payload = {
      customerId: getCustomerId(),
      datastores: ids,
    };
    let res = await dispatch(listDataStoreNames(payload));
    if (res && res.payload) {
      setDatastoreMappings(res.payload);
    }
  };

  const getPolicyDetails = async (category) => {
    setIsFetchingPolicyStoreDetails(true);
    let payload = {
      customerId: getCustomerId(),
      category: category,
    };
    const resp = await dispatch(fetchLLMPolicyViolationsSlice(payload));
    if (resp?.payload && resp?.payload?.length > 0) {
      setPolicyStoreDetails(resp?.payload);
    } else {
      setPolicyStoreDetails([]);
    }
    setIsFetchingPolicyStoreDetails(false);
  };

  useEffect(() => {
    if (!compliancePolicies?.length) return;
    let ids: Array<any> = [];
    for (let i of compliancePolicies) {
      for (let j of i?.target?.datastoreIds) {
        ids.push(j);
      }
    }
    getDatastoreNames(ids);
  }, [compliancePolicies]);

  useEffect(() => {
    getPolicyDetails(drawerData?.currentSelectedPolicy?.policyType);
  }, [drawerData?.currentSelectedPolicy?.policyType]);

  useEffect(() => {
    if (policyStoreDetails?.length > 0) {
      const totalCount = policyStoreDetails.reduce(
        (accumulator, item) => accumulator + item?.dsCount,
        0
      );
      const totalViolationCount = policyStoreDetails.reduce(
        (accumulator, item) => accumulator + item?.violationCount,
        0
      );
      const policyName =
        drawerData?.currentSelectedPolicy?.barLabel ||
        drawerData?.currentSelectedPolicy?.name;

      const policyType = drawerData?.currentSelectedPolicy?.policyType;
      let policyData: any = [];
      if (drawerData?.activeTab === "policy-category") {
        policyData.push({
          isAggregate: true,
          title: `All ${policyName} Policies (${policyStoreDetails?.length})`,
          dsCount: totalCount,
          policyType,
          violationCount: totalViolationCount,
          id: policyType + "0",
        });
      }
      policyData = [...policyData, ...policyStoreDetails];
      setSelectedPolicyCategoryPolicies(policyData);
      setMainData(policyData);
    } else {
      setSelectedPolicyCategoryPolicies([]);
      setMainData([]);
    }
  }, [drawerData, policyStoreDetails]);

  useEffect(() => {
    if (drawerData?.activeTab === "policy-category")
      setSelectedPolicy(selectedPolicyCategoryPolicies[0]);

    if (
      (!selectedPolicy || selectedPolicy?.id === "") &&
      drawerData?.activeTab === "policy"
    )
      setSelectedPolicy(policyData[0]);
  }, [selectedPolicyCategoryPolicies]);

  useEffect(() => {
    if (onSearch?.isEnter && onSearch?.searchText) {
      const filteredData = selectedPolicyCategoryPolicies?.filter((row) =>
        row?.title
          ?.toLocaleLowerCase()
          .includes(onSearch?.searchText.toLocaleLowerCase())
      );
      setMainData(filteredData);
    } else {
      setMainData(selectedPolicyCategoryPolicies);
    }
  }, [onSearch?.isEnter]);

  const modifyPolicy = (policy) => {
    console.log({ policy });
    setModifyPolicyData(policy);
  };

  useEffect(() => {
    if (policyId) {
      getModifyPolicyDetails(policyId);
    }
  }, [policyId]);

  useEffect(() => {
    if (selectedPolicy?.id) {
      const el = document.querySelector("#row-selected");
      if (el) el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [document.getElementById("row-selected")]);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const getEditPolicyDetails = () => {
    let policyDetails: any = null;
    if (policyInformationList?.length > 0 && modifyPolicyData) {
      policyDetails = policyInformationList.find(
        (policy: { id: string }) => policy?.id === modifyPolicyData?.policyCfgId
      );
    }
    return policyDetails;
  };

  const getPredefinesPolicieList = async () => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: selectedAccountIDs ? selectedAccountIDs : "",
    };
    let res = await dispatch(getAllPolicies(payload));
    let updatedPolicies: Array<{ value: string; label: string }> = [];
    if (res && res.payload && !res.payload.err) {
      res.payload.map((policy) => {
        updatedPolicies.push({
          value: policy.policyId,
          label: policy.description,
        });
      });
      setPoliciesList(updatedPolicies);
    }
  };

  useEffect(() => {
    getPredefinesPolicieList();
  }, []);

  const refreshAccounts = async () => {
    getAllPoliciesList();
  };

  const getAllPoliciesList = async () => {
    let res = await dispatch(listPoliciesAsync());
    if (res && res.payload && !res.payload.err) {
      if (res.payload && res.payload.length > 0) {
        let updatedCompliancePolicy: Array<any> = [];
        let updatedAlertingPolicy: Array<any> = [];
        res.payload.map((policy) => {
          if (policy.category == "alerting") {
            updatedAlertingPolicy.push(policy);
          }
          if (policy.category == "compliance") {
            updatedCompliancePolicy.push(policy);
          }
        });
        setAlertingPolicies(updatedAlertingPolicy);
        if (policyId) {
          setCompliancePolicies(
            filterCompliancePolicies(updatedCompliancePolicy)
          );
        } else {
          setCompliancePolicies(updatedCompliancePolicy);
        }
      }
    }
  };

  const filterCompliancePolicies = (updatedPolicies) => {
    let filteredPolicies = [];
    filteredPolicies = updatedPolicies.filter(
      (policyItem) => policyItem.policyId === policyId
    );
    return filteredPolicies;
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Policy Type</TableCell>
              <TableCell>Policy Tags</TableCell>
              <TableCell align="right">Violation Instance</TableCell>
              <TableCell align="right">Impacted Frameworks</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          {!isFetchingPolicyStoreDetails && (
            <TableBody>
              {mainData?.length > 0 ? (
                mainData?.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    selected={row?.id === selectedPolicy?.id}
                    onClick={() => setSelectedPolicy(row)}
                    id={row?.id === selectedPolicy?.id ? "row-selected" : ""}
                  >
                    <TableCell width="25%" component="td" scope="row">
                      <Typography variant="caption">{row?.title} </Typography>
                      {!row.isAggregate && row?.id ? (
                        <>
                          <br />
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.surface60.main }}
                          >
                            Policy ID: {row?.id}
                          </Typography>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell width="15%" component="td" scope="row">
                      <Typography variant="caption">
                        {row?.policyTags || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width="20%"
                      component="td"
                      scope="row"
                      align="right"
                    >
                      <Typography variant="caption">
                        {row?.violationCount || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width="20%"
                      component="td"
                      scope="row"
                      align="right"
                    >
                      <Typography variant="caption">{row?.dsCount}</Typography>
                    </TableCell>
                    <TableCell width="20%" component="td" scope="row">
                      {!row?.isAggregate && (
                        <Box sx={styles.tableCellLast}>
                          <TextButton
                            startIcon={
                              <ConstructionIcon sx={styles.moreIcon} />
                            }
                            sx={styles.tableCellAction}
                            onClick={(event) => {
                              event.stopPropagation();
                              modifyPolicy(row);
                            }}
                          >
                            Modify Policy
                          </TextButton>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={styles.tableActionDivider}
                          />
                          <MoreVertIcon sx={styles.moreIcon} />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell colspan="5" borderBottom={false}>
                  <NoData
                    customText={
                      onSearch?.searchText
                        ? `No search results containing ${onSearch?.searchText} found`
                        : "No data found."
                    }
                  />
                </TableCell>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isFetchingPolicyStoreDetails && <Loader />}
      {modifyPolicyData?.id !== "" && (
        <AddPolicy
          isOpen={modifyPolicyData?.id !== ""}
          isEdit={modifyPolicyData?.id !== ""}
          selectedAccount={[]}
          handleClose={() => {
            setModifyPolicyData({ id: "", policyCfgId: "" });
          }}
          fetchPolicies={fetchPolicies}
          buttonText="Update"
          dialogHeader="Modify Policy"
          policyDetails={getEditPolicyDetails()}
        />
      )}
    </>
  );
};
export default PolicyStoreTable;
