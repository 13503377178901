import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";

import Typography from "../../components/common/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../components/common/Button";

import { TextField } from "../../components/common/TextField";
import { SyntheticEvent, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Dialog, DialogActions } from "../common/Dialog";
import { Autocomplete } from "../common/AutoComplete";
import Checkbox from "../common/Checkbox";
import { useDispatch } from "react-redux";
import {
  getAllPolicies,
  addPolicyAsync,
  fetchEntitiesSlice,
  fetchTopicsSlice,
  updatePolicyAsync,
} from "../../redux/slices/policySlice";
import { getCustomerId, getUserId } from "../../utils/SessionHelper";
import {
  fetchLLMModelsListingSlice,
  fetchFrameworksSlice,
} from "../../redux/slices/dashboardSlice";
import { getSelectedAccountIds } from "../../utils/commonUtils";
import Loader from "../common/Loader";
import { entityPolicy, topicPolicy } from "../constants/constants";
import DiscardChanges from "../common/DiscardChanges";

const frameworkType = "framework";
const llmType = "llm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  isEdit: boolean;
  isOpen: boolean;
  handleClose: () => void;
  selectedAccount: any;
  fetchPolicies: () => {};
  buttonText?: string;
  dialogHeader?: string;
  policyDetails: any;
}

const allTopics = "All Topics";
const allFrameworks = "All Frameworks";
const allModels = "All Models";
const allEntities = "All Entities";

export const AddPolicy = ({
  isEdit,
  isOpen,
  handleClose,
  selectedAccount,
  fetchPolicies,
  buttonText,
  dialogHeader,
  policyDetails,
}: Props) => {
  const [policyCategories, setPolicyCategories] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [frameworks, setFrameworks] = useState<Array<string>>([]);
  const [models, setModels] = useState<Array<string>>([]);
  const [entities, setEntities] = useState<Array<string>>([]);
  const [topics, setTopics] = useState<Array<string>>([]);
  const [showTopicEntity, setShowTopicEntity] = useState("");
  const [onEditShowDiscard, setOnEditShowDiscard] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [isEmptyField, setIsEmptyField] = useState({
    topic: false,
    entity: false,
    framework: false,
    model: false,
    policy: false,
    name: false,
  });
  const [policyData, setPolicyData] = useState<{
    type: string;
    name: string;
    topic: Array<string>;
    entities: Array<string>;
    frameworkIds: Array<string>;
    modelIds: Array<string>;
    policies: Array<{ rule: string; description: string }>;
  }>({
    type: "",
    name: "",
    topic: [],
    entities: [],
    frameworkIds: [],
    modelIds: [],
    policies: [],
  });
  const theme = useTheme();
  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      ".MuiPaper-root": {
        background: theme.palette.surface0.main,
      },
    },
    dialogHeader: {
      display: "flex",
      padding: 3,
      alignItems: "center",
    },
    backIcon: {
      color: theme.palette.surface50.main,
      fontSize: theme.spacing(3),
      width: theme.spacing(3),
    },
    dialogBody: {
      display: "flex",
      flex: 1,
      borderBottom: `0.5px solid ${theme.palette.surface20.main}`,
      paddingX: 7.5,
      paddingY: 2,
      flexDirection: "column",
      gap: theme.spacing(4.5),
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      width: "40%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    formHeading: {
      fontSize: "14px",
      opacity: 0.6,
    },
  };

  const getPolicyCategories = async (payload) => {
    setIsLoading(true);
    const res = await dispatch(getAllPolicies(payload));
    if (res.payload) {
      setPolicyCategories(
        res.payload.map((policy) => ({ ...policy, label: policy?.description }))
      );
    }
    setIsLoading(false);
  };

  const getAllFrameworks = async (payload) => {
    const res = await dispatch(fetchFrameworksSlice(payload));
    if (res.payload) {
      const frameworkList = res.payload.data?.map(
        (framework) => framework?.name
      );
      setFrameworks([allFrameworks, ...frameworkList]);
    }
  };

  const getAllModels = async (payload) => {
    const res = await dispatch(fetchLLMModelsListingSlice(payload));
    if (res.payload) {
      const modelList = res.payload.data?.map((model) => model?.name);
      setModels([allModels, ...modelList]);
    }
  };

  const getAllEntities = async (payload) => {
    const res = await dispatch(fetchEntitiesSlice(payload));
    if (res.payload) {
      const entitiesList = res.payload?.map((entity) => entity?.name);
      setEntities([allEntities, ...entitiesList]);
    }
  };

  const getAllTopics = async (payload) => {
    const res = await dispatch(fetchTopicsSlice(payload));
    if (res.payload) {
      const topicList = res.payload?.map((topic) => topic?.name);
      setTopics([allTopics, ...topicList]);
    }
  };

  const onEditCheckStateModify = () => {
    if (isEdit) {
      setOnEditShowDiscard(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      let payload = {
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
      };
      getPolicyCategories(payload);
      getAllModels(payload);
      getAllFrameworks(payload);
      getAllEntities(payload);
      getAllTopics(payload);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      const flag = topicPolicy.includes(policyDetails.policyId)
        ? "topic"
        : entityPolicy.includes(policyDetails.policyId)
        ? "entity"
        : "";
      const typeValue =
        policyDetails?.policyId === "apRestrictedTopic" ||
        policyDetails?.policyId === "apSensitiveIdentifier" ||
        policyDetails?.policyId === "apRestrictedEntities"
          ? frameworkType
          : llmType;
      setShowTopicEntity(flag);
      setSelectedType(typeValue);
      setPolicyData({
        type: selectedAccount.length ? "accounts" : "customer",
        name: policyDetails?.name,
        topic:
          flag === "topic" && policyDetails.topics?.length === 0
            ? [allTopics]
            : policyDetails.topics,
        entities:
          flag === "entity" && policyDetails.entities?.length === 0
            ? [allEntities]
            : policyDetails.entities,
        frameworkIds:
          typeValue === frameworkType &&
          policyDetails?.target?.frameworkIds?.length === 0
            ? [allFrameworks]
            : policyDetails?.target?.frameworkIds,
        modelIds:
          typeValue === llmType && policyDetails?.target?.modelIds?.length === 0
            ? [allModels]
            : policyDetails?.target?.modelIds,
        policies: [
          { rule: policyDetails?.policyId, description: policyDetails?.policy },
        ],
      });
    }
  }, [policyDetails, policyCategories]);

  const getArrayValue = (array, option) => {
    return array.includes(option) ? [] : array;
  };

  const validateInputFields = () => {
    const validationFlags = {
      name: !policyData?.name && policyData?.name === "",
      policy: policyData?.policies?.length === 0,
      framework:
        selectedType === frameworkType &&
        policyData?.frameworkIds?.length === 0,
      model: selectedType === llmType && policyData?.modelIds?.length === 0,
      entity:
        showTopicEntity === "entity" && policyData?.entities?.length === 0,
      topic: showTopicEntity === "topic" && policyData?.topic?.length === 0,
    };
    setIsEmptyField(validationFlags);
    return validationFlags;
  };

  const clearStateAndCloseDialog = () => {
    setPolicyData({
      type: "",
      name: "",
      topic: [],
      entities: [],
      frameworkIds: [],
      modelIds: [],
      policies: [],
    });
    setShowDiscardDialog(false);
    setOnEditShowDiscard(false);
    setSelectedType("");
    setShowTopicEntity("");
    setIsEmptyField({
      topic: false,
      entity: false,
      framework: false,
      model: false,
      policy: false,
      name: false,
    });
    handleClose();
  };

  const openDiscardChangesDialog = () => {
    if (
      !isEdit &&
      (policyData?.policies?.length > 0 ||
        policyData?.name !== "" ||
        policyData?.topic?.length > 0 ||
        policyData?.entities?.length > 0 ||
        policyData?.frameworkIds?.length > 0 ||
        policyData?.modelIds?.length > 0)
    ) {
      setShowDiscardDialog(true);
    } else if (isEdit && onEditShowDiscard) {
      setShowDiscardDialog(true);
    } else {
      clearStateAndCloseDialog();
    }
  };

  const handleSubmit = async () => {
    const validationFlags = validateInputFields();
    if (Object.values(validationFlags).includes(true)) {
      return;
    } else {
      const payload = {
        cloudAccountIds: getSelectedAccountIds(selectedAccount),
        type: selectedAccount.length ? "accounts" : "customer",
        policies: policyData.policies,
        name: policyData.name,
        userId: getUserId(),
        topics: getArrayValue(policyData?.topic, allTopics),
        entities: getArrayValue(policyData?.entities, allEntities),
        category: "compliance",
      };
      const policyLLM =
        selectedType === frameworkType
          ? {
              frameworkIds: getArrayValue(
                policyData?.frameworkIds,
                allFrameworks
              ),
            }
          : {
              modelIds: getArrayValue(policyData.modelIds, allModels),
            };
      const editPolicy = { id: policyDetails?.id };
      let res;
      if (isEdit) {
        res = await dispatch(
          updatePolicyAsync({ ...payload, ...policyLLM, ...editPolicy })
        );
      } else {
        res = await dispatch(
          addPolicyAsync({
            ...payload,
            ...policyLLM,
          })
        );
      }
      if (res?.payload) {
        fetchPolicies();
      }
      clearStateAndCloseDialog();
    }
  };

  useEffect(() => {
    if (showTopicEntity === "topic" && policyData.entities.length > 0) {
      setPolicyData({
        ...policyData,
        entities: [],
      });
    } else if (showTopicEntity === "entity" && policyData.topic.length > 0) {
      setPolicyData({
        ...policyData,
        topic: [],
      });
    }
  }, [showTopicEntity]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      sx={styles.dialog}
      onClose={openDiscardChangesDialog}
    >
      <Box sx={styles.dialogHeader}>
        <IconButton onClick={openDiscardChangesDialog}>
          <ChevronLeftIcon sx={styles.backIcon} />
        </IconButton>
        <Typography variant="subtitle1" color={theme.palette.surface50.main}>
          {dialogHeader}
        </Typography>
      </Box>
      <Box sx={styles.dialogBody}>
        {isLoading ? (
          <Loader height="200px" />
        ) : (
          <>
            <Box sx={styles.formBox}>
              <Typography
                color={theme.palette.surface50.main}
                sx={styles.formHeading}
              >
                Policy Details
              </Typography>
              <Box sx={styles.form}>
                <Autocomplete
                  label="Type"
                  variant="standard"
                  error={isEmptyField.policy}
                  helperText={
                    isEmptyField.policy && "Please select policy type"
                  }
                  disabled={isEdit}
                  options={policyCategories}
                  value={policyData?.policies[0]?.description}
                  onChange={(e: Event, newValue) => {
                    onEditCheckStateModify();
                    if (newValue?.policyId !== "" && isEmptyField.policy) {
                      setIsEmptyField({ ...isEmptyField, policy: false });
                    }
                    setSelectedType(newValue?.type);
                    setPolicyData({
                      ...policyData,
                      policies: [
                        {
                          rule: newValue?.policyId || "",
                          description: newValue?.description || "",
                        },
                      ],
                    });
                    const flag = topicPolicy.includes(newValue.policyId)
                      ? "topic"
                      : entityPolicy.includes(newValue.policyId)
                      ? "entity"
                      : "";
                    setShowTopicEntity(flag);
                  }}
                />
                {showTopicEntity && (
                  <>
                    <TextField
                      label="Name"
                      variant="standard"
                      fullWidth
                      error={isEmptyField.name}
                      helperText={
                        isEmptyField.name &&
                        "Please provide custom name for policy"
                      }
                      placeholder="Custom Name"
                      value={policyData?.name}
                      onChange={(e) => {
                        onEditCheckStateModify();
                        const textValue = e.target.value;
                        if (textValue !== "" && isEmptyField.name) {
                          setIsEmptyField({ ...isEmptyField, name: false });
                        }
                        setPolicyData({ ...policyData, name: textValue });
                      }}
                    />
                    {showTopicEntity === "topic" && (
                      <Autocomplete
                        multiple
                        label="Topic"
                        variant="standard"
                        options={topics}
                        error={isEmptyField.topic}
                        helperText={
                          isEmptyField.topic &&
                          "Please select at least one topic before proceeding"
                        }
                        value={policyData?.topic}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              label="topic"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                policyData?.topic?.includes(option) ||
                                policyData?.topic?.includes(allTopics)
                              }
                            />
                            {option}
                          </li>
                        )}
                        onChange={(e: Event, newValue: Array<string>) => {
                          onEditCheckStateModify();
                          if (newValue.length > 0 && isEmptyField?.topic) {
                            setIsEmptyField({ ...isEmptyField, topic: false });
                          }
                          if (newValue.includes(allTopics))
                            setPolicyData({
                              ...policyData,
                              topic: [allTopics],
                            });
                          else
                            setPolicyData({
                              ...policyData,
                              topic: newValue,
                            });
                        }}
                      />
                    )}
                    {showTopicEntity === "entity" && (
                      <Autocomplete
                        multiple
                        label="Entity"
                        variant="standard"
                        options={entities}
                        value={policyData?.entities}
                        error={isEmptyField.entity}
                        helperText={
                          isEmptyField.entity &&
                          "Please select at least one entity before proceeding"
                        }
                        limitTags={3}
                        //getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              label="entity"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                policyData?.entities?.includes(option) ||
                                policyData?.entities?.includes(allEntities)
                              }
                            />
                            {option}
                          </li>
                        )}
                        onChange={(e: Event, newValue: Array<string>) => {
                          onEditCheckStateModify();
                          if (newValue?.length > 0 && isEmptyField.entity) {
                            setIsEmptyField({ ...isEmptyField, entity: false });
                          }
                          if (newValue.includes(allEntities))
                            setPolicyData({
                              ...policyData,
                              entities: [allEntities],
                            });
                          else
                            setPolicyData({
                              ...policyData,
                              entities: newValue,
                            });
                        }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box sx={styles.formBox}>
              {selectedType && (
                <Typography
                  color={theme.palette.surface50.main}
                  sx={styles.formHeading}
                >
                  Target
                </Typography>
              )}
              {selectedType === frameworkType && (
                <Box sx={styles.form}>
                  <Autocomplete
                    multiple
                    label="Framework"
                    variant="standard"
                    options={frameworks}
                    getOptionLabel={(option) => option}
                    error={isEmptyField.framework}
                    helperText={
                      isEmptyField.framework &&
                      "Please select at least one framework before proceeding"
                    }
                    value={policyData?.frameworkIds}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          label={frameworkType}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            policyData?.frameworkIds?.includes(option) ||
                            policyData?.frameworkIds?.includes(allFrameworks)
                          }
                        />
                        {option}
                      </li>
                    )}
                    onChange={(e: Event, newValue) => {
                      onEditCheckStateModify();
                      if (newValue?.length > 0 && isEmptyField.framework) {
                        setIsEmptyField({ ...isEmptyField, framework: false });
                      }
                      if (newValue.includes(allFrameworks))
                        setPolicyData({
                          ...policyData,
                          frameworkIds: [allFrameworks],
                        });
                      else
                        setPolicyData({
                          ...policyData,
                          frameworkIds: newValue,
                        });
                    }}
                  />
                </Box>
              )}
              {selectedType === llmType && (
                <Box sx={styles.form}>
                  <Autocomplete
                    multiple
                    label="Model"
                    variant="standard"
                    options={models}
                    error={isEmptyField.model}
                    helperText={
                      isEmptyField.model &&
                      "Please select at least one model before proceeding"
                    }
                    value={policyData?.modelIds}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          label={llmType}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            policyData?.modelIds?.includes(option) ||
                            policyData?.modelIds?.includes(allModels)
                          }
                        />
                        {option}
                      </li>
                    )}
                    onChange={(e: Event, newValue) => {
                      onEditCheckStateModify();
                      if (newValue?.length > 0 && isEmptyField.model) {
                        setIsEmptyField({ ...isEmptyField, model: false });
                      }
                      if (newValue.includes(allModels))
                        setPolicyData({ ...policyData, modelIds: [allModels] });
                      else setPolicyData({ ...policyData, modelIds: newValue });
                    }}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={openDiscardChangesDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </DialogActions>
      <DiscardChanges
        open={showDiscardDialog}
        onClose={() => setShowDiscardDialog(false)}
        onSubmit={clearStateAndCloseDialog}
      />
    </Dialog>
  );
};
