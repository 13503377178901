import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchPolicies,
  addPolicy,
  deletePolicy,
  updatePolicy,
  getPolicies,
  getPolicyStats,
  acceptPolicyViolation,
  policyNotify,
  archivePolicy,
  policyViolationDetails,
  fetchPolicyViolations,
  fetchAIPolicyViolations,
  fetchEntities,
  fetchTopics,
} from "../apis/policyAPI";

const initialState = {
  policies: [],
  policyViolationInstances: 0,
  entities: [],
  topics: [],
};

// Async calls.
export const listPoliciesAsync = createAsyncThunk("policy/list", async () => {
  const response = await fetchPolicies();
  return response.data;
});

export const getAllPolicies = createAsyncThunk("policy/all", async (params) => {
  const response = await getPolicies(params);
  return response.data;
});

export const getPolicyStatsAsync = createAsyncThunk(
  "policy/stats",
  async (params) => {
    const response = await getPolicyStats(params);
    return response.data;
  }
);

export const addPolicyAsync = createAsyncThunk(
  "policy/addPolicy",
  async (params) => {
    try {
      const response = await addPolicy(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const updatePolicyAsync = createAsyncThunk(
  "integration/updatePolicy",
  async (params) => {
    const response = await updatePolicy(params.id, params);
    return response.data;
  }
);

export const acceptPolicyViolationAsync = createAsyncThunk(
  "policy/acceptViolation",
  async (params) => {
    const response = await acceptPolicyViolation(params);
    return response.data;
  }
);

export const deletePolicyAsync = createAsyncThunk(
  "policy/deletePolicy",
  async (params) => {
    try {
      const response = await deletePolicy(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const policyNotifyAsync = createAsyncThunk(
  "policy/notify",
  async (params) => {
    try {
      const response = await policyNotify(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const archivePolicyAsync = createAsyncThunk(
  "policy/notify",
  async (params) => {
    try {
      const response = await archivePolicy(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const policyViolationDetailsAsync = createAsyncThunk(
  "policy/detail",
  async (params) => {
    try {
      const response = await policyViolationDetails(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const fetchPolicyViolationsSlice = createAsyncThunk(
  "policy/policyViolations",
  async (params) => {
    try {
      const response = await fetchPolicyViolations(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const fetchAIPolicyViolationsSlice = createAsyncThunk(
  "policy/policyViolations",
  async (params) => {
    try {
      const response = await fetchAIPolicyViolations(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const fetchEntitiesSlice = createAsyncThunk(
  "policy/entities",
  async (params) => {
    try {
      const response = await fetchEntities(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const fetchTopicsSlice = createAsyncThunk(
  "policy/topics",
  async (params) => {
    try {
      const response = await fetchTopics(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

// Account slice for configuring reducers and actions.
export const policySlice = createSlice({
  name: "policy",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listPoliciesAsync.fulfilled, (state, action) => {
        state.policies = action.payload;
      })
      .addCase(listPoliciesAsync.rejected, (state, action) => {
        state.policies = [];
      })
      .addCase(fetchAIPolicyViolationsSlice.fulfilled, (state, action) => {
        state.policyViolationInstances = action?.payload?.err
          ? 0
          : action?.payload?.count;
      })
      .addCase(fetchEntitiesSlice.fulfilled, (state, action) => {
        state.entities = action?.payload?.error ? [] : action?.payload;
      })
      .addCase(fetchTopicsSlice.fulfilled, (state, action) => {
        state.topics = action?.payload?.error ? [] : action?.payload;
      });
  },
});

export default policySlice.reducer;

// Selectors will go here
export const policyById = (state, policyId) =>
  state.integrations.find((policy) => policy.id === policyId);
