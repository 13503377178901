import { useTheme } from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import Box from "../common/Box";
import { Tab, Tabs } from "../common/Tabs";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { ActionCardProps, CardDataTab } from "./type";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Loader from "../common/Loader";

const CardWithAction = ({
  cardData,
  buttonTitle,
  onClick,
  isLoading = false,
}: ActionCardProps) => {
  const hasTabs = cardData && Object.keys(cardData).length > 1;
  const theme = useTheme();
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0),
      height: "100%",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
      paddingTop: hasTabs ? theme.spacing(1.5) : theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
    },
    children: { height: "100%" },
  };

  const [activeTab, setActiveTab] = useState("");

  const titleCardData: CardDataTab =
    !hasTabs && cardData ? Object.values(cardData)[0] : null;

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (hasTabs) {
      setActiveTab(Object.keys(cardData)[0]);
    }
  }, [cardData]);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Box>
          {hasTabs ? (
            <Tabs value={activeTab} onChange={handleTabChange}>
              {Object.keys(cardData).map((tab: string) => (
                <Tab value={tab} label={cardData[tab]?.title} key={tab} />
              ))}
            </Tabs>
          ) : (
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              {titleCardData?.title}
            </Typography>
          )}
        </Box>
        {buttonTitle ? (
          <TextButton
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => onClick(activeTab)}
          >
            {buttonTitle}
          </TextButton>
        ) : null}
      </Box>
      <Box sx={styles.children}>
        {isLoading ? (
          <Loader height="250px" />
        ) : hasTabs ? (
          cardData[activeTab]?.children
        ) : (
          titleCardData?.children
        )}
      </Box>
    </Box>
  );
};

export default CardWithAction;
