import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../../common/Accordion";
import Box from "../../../../common/Box";
import NoData from "../../../../common/NoData";
import Typography from "../../../../common/Typography";
import { SNIPPET_COUNT_KEY } from "../../../../constants/constants";
import SnippetDetailsDialog from "../SnippetDetailsDialog";
import FindingRow from "./FindingRow";
import { getCustomerId } from "../../../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import { fetchSnippetsSlice } from "../../../../../redux/slices/dataLoaderSlice";
import { fetchAppRetreivalFindingsSlice } from "../../../../../redux/slices/appSlice";

const TOPIC = "topics";
const ENTITY = "entities";

const FindingsDetails = ({
  selectedFirstValue,
  selectedSecondValue,
  findings,
  isDataLoader,
}) => {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const [showSnippetDialog, setShowSnippetDialog] = useState(false);
  const [snippets, setSnippets] = useState("");
  const [selectedFinding, setSelectedFinding] = useState({
    label: "",
    type: "",
  });

  const theme = useTheme();
  const styles = {
    container: {
      backgroundColor: theme.palette.surfaceCard.main,
    },
    accordion: {
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: "0px",
      },
    },
    accordionSummary: {
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.surface20.main}`,
    },
    accordionContentWrapper: {
      paddingBottom: theme.spacing(2),
    },
  };

  const fetchSnippets = async (labelName, label) => {
    const commonPayload = {
      customerId: getCustomerId(),
      label,
    };
    if (isDataLoader) {
      const response = await dispatch(
        fetchSnippetsSlice({
          ...commonPayload,
          dataLoaderId: params.id,
          dataSourceId: selectedFirstValue?.data_source_id,
          documentId: selectedSecondValue?.id,
          labelName,
        })
      );
      if (response && response?.payload) {
        setSnippets(response.payload?.data);
        setShowSnippetDialog(true);
      }
    } else {
      const response = await dispatch(
        fetchAppRetreivalFindingsSlice({
          ...commonPayload,
          appId: params.id,
          userId: selectedFirstValue?.user_id,
          finding: labelName,
          docId: selectedSecondValue?.id,
        })
      );
      if (response && response?.payload) {
        setSnippets(response.payload);
        setShowSnippetDialog(true);
      }
    }
  };

  const topics = Object.keys(findings?.topics ? findings?.topics : {});
  const entities = Object.keys(findings?.entities ? findings?.entities : {});

  return (
    <Box sx={styles.container}>
      <Accordion sx={styles.accordion}>
        <AccordionSummary sx={styles.accordionSummary}>
          <Typography color={theme.palette.surface40.main} variant="caption">
            Topics ({topics.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.accordionContentWrapper}>
            {topics.length > 0 ? (
              topics.map((topic) => (
                <FindingRow
                  name={topic}
                  value={findings.topics[topic]}
                  isDataLoader={isDataLoader}
                  onClick={() => {
                    fetchSnippets(topic, TOPIC);
                    setSelectedFinding({ label: topic, type: TOPIC });
                  }}
                />
              ))
            ) : (
              <NoData customText="No Topics Found" />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={styles.accordion}>
        <AccordionSummary sx={styles.accordionSummary}>
          <Typography color={theme.palette.surface40.main} variant="caption">
            Entities ({entities.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.accordionContentWrapper}>
            {entities.length > 0 ? (
              entities.map((entity) => (
                <FindingRow
                  name={entity}
                  value={findings.entities[entity]}
                  isDataLoader={isDataLoader}
                  onClick={() => {
                    fetchSnippets(entity, ENTITY);
                    setSelectedFinding({ label: entity, type: ENTITY });
                  }}
                />
              ))
            ) : (
              <NoData customText="No Entities Found" />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      {showSnippetDialog && (
        <SnippetDetailsDialog
          snippets={snippets}
          open={showSnippetDialog}
          label={selectedFinding.label}
          file={selectedSecondValue?.name}
          isDataLoader={isDataLoader}
          onClose={() => {
            setSelectedFinding({ label: "", type: "" });
            setShowSnippetDialog(false);
          }}
        />
      )}
    </Box>
  );
};

export default FindingsDetails;
