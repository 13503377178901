import { useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import DonutChart from "../common/DonutChart";
import LegendWithColor from "../common/LegendWithColor";
import Loader from "../common/Loader";
import MetricBlock from "../common/MetricBlock";
import Typography from "../common/Typography";
import ImpactedDataCard from "./ImpactedDonutCard";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchFindingOverviewSlice } from "../../redux/slices/dashboardSlice";

const ImpactedDataStoresPanel = () => {
  const {
    impactedDataStores,
    isFetchingImpactedStoresData,
    aiSummary,
    getAISummary,
    getImpactedStoresData,
  } = useContext(AppContext);
  const [findingOverview, setFindingOverview] = useState<any>([]);
  const theme = useTheme();
  const dispatch = useDispatch<any>();

  const styles = {
    donutCardContainer: {
      position: "relative",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      minHeight: "220px",
      padding: theme.spacing(2),
    },
    donuts: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      alignItems: "center",
      paddingX: theme.spacing(6),
    },
    allDataImpactedText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "5rem",
    },
    sensitiveDataText: {
      color: theme.palette.surface70.main,
      fontWeight: theme.typography.fontWeightRegular,
      maxWidth: "9.375rem",
    },
    highlightDataText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    legendContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      alignItems: "center",
      zIndex: 20,
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    legendGroup: {
      display: "flex",
      gap: theme.spacing(3),
    },
    totalDataStore: {
      opacity: 0.5,
    },
    font13: {
      fontSize: "13px",
    },
  };

  const fetchFindingOverview = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchFindingOverviewSlice(payload));
    if (response.payload) {
      const metrics = response.payload;
      setFindingOverview(metrics);
    }
  };

  const donutItems = useMemo(() => {
    if (findingOverview) {
      return [
        {
          id: "data-loader",
          label: "Data Loaders",
          showCenterText: false,
          isDonut: false,
          value: findingOverview?.dataloaders?.total,
          outOf: 0,
        },
        {
          id: "documents",
          value: findingOverview?.documents?.total,
          outOf: 0,
          label: "Documents",
          showCenterText: false,
          isDonut: false,
        },
        {
          id: "divider1",
          value: 34,
          outOf: 427,
          label: "Documents",
          showCenterText: false,
          isDonut: false,
          isDivider: true,
        },
        {
          id: "applications",
          label: "Applications",
          showCenterText: true,
          isDonut: true,
          donutData: [
            {
              value: findingOverview?.applications?.with_findings || 0,
              color: theme.palette.pictonBlue.main,
              label: "Langchain",
            },
          ],
          totalValue: findingOverview?.applications?.total,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 140,
          outerRadius: 150,
          boxSize: 390,
          donutHeight: 200,
          donutWidth: 200,
        },
        {
          id: "users",
          value: findingOverview?.users?.total,
          outOf: 0,
          label: "Users",
          showCenterText: false,
          isDonut: false,
        },
        {
          id: "documents-accessed",
          value: findingOverview?.documents_accessed?.total,
          outOf: 0,
          label: "Documents Accessed",
          showCenterText: false,
          isDonut: false,
        },
        {
          id: "llms",
          value: findingOverview?.llm?.total,
          outOf: 0,
          label: "LLMs",
          showCenterText: false,
          isDonut: false,
        },
        {
          id: "divider2",
          value: 34,
          outOf: 427,
          label: "Documents",
          showCenterText: false,
          isDonut: false,
          isDivider: true,
        },
        {
          id: "policy",
          label: "Policy",
          value: findingOverview?.policy?.total,
          outOf: 0,
          showCenterText: false,
          isDonut: false,
        },
      ];
    } else return [];
  }, [findingOverview]);

  useEffect(() => {
    fetchFindingOverview();
  }, []);

  return (
    <Box sx={styles.donutCardContainer}>
      <Typography color={theme.palette.surface80.main} sx={styles.font13}>
        Findings Overview
      </Typography>
      <Box sx={styles.donuts}>
        {isFetchingImpactedStoresData ? (
          <Loader />
        ) : (
          <>
            {donutItems.map((item: any) => {
              if (item.isDivider)
                return <Divider orientation="vertical" flexItem />;
              else if (item.isDonut) {
                return (
                  <DonutChart
                    donutData={item.donutData}
                    totalValue={item.totalValue || 0}
                    defaultColor={item.defaultColor}
                    variant="AutoColor"
                    innerRadius={item.innerRadius}
                    outerRadius={item.outerRadius}
                    boxSize={item.boxSize}
                    donutHeight={item.donutHeight}
                    donutWidth={item.donutWidth}
                    showCenterText={item.showCenterText}
                    label={item.label}
                  />
                );
              } else {
                return (
                  <MetricBlock
                    value={item.value || 0}
                    outOf={item.outOf || 0}
                    isCritical={false}
                    textColor={theme.palette.surface50.main}
                    label={item.label}
                  />
                );
              }
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ImpactedDataStoresPanel;
