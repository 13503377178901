import { SxProps, useTheme } from "@mui/material";
import MUITypography from "@mui/material/Typography";
import { PropsWithChildren } from "react";

const typographyVariant = {
  tooltip: "10px",
  subHeader: "28px",
} as const;

type variantTypes =
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "button"
  | "overline"
  | "inherit"
  | "tooltip"
  | "subHeader"
  | undefined;

export interface Props {
  color?: string;
  variant?: variantTypes;
  sx?: SxProps;
  component?: string;
  align?: "inherit" | "left" | "right" | "center" | "justify" | undefined;
  id?: string;
  onClick?: () => void;
}

const Typography = ({
  color = "",
  variant = "subtitle1",
  sx = {},
  id,
  onClick,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const defaultColor = color !== "" ? color : theme.palette.surface80.main;
  const fontSize = (
    variant === "tooltip" || variant === "subHeader"
      ? typographyVariant[variant]
      : null
  ) as string;
  const variantMap = (
    variant !== "tooltip" && variant !== "subHeader" ? variant : undefined
  ) as any;
  const styles = {
    typographyText: {
      lineHeight: "116.7%",
      fontSize,
      fontFamily: "Inter",
    },
  };

  return (
    <MUITypography
      variant={variantMap || variant}
      color={defaultColor}
      id={id}
      onClick={onClick}
      sx={{ ...styles.typographyText, ...sx }}
      {...otherProps}
    />
  );
};

export default Typography;
