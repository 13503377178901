import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "../common/Box";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Link } from "../common/Breadcrumbs";
import { TabPanel } from "../common/Tabs";
import Typography from "../common/Typography";
import LLMFrameworkDetails from "../llm/LLMFramework/LLMFrameworkDetails";
import LLMTabs from "../llm/LLMFramework/LLMTabs";
import LoaderOverviewPanel from "../llm/LLMFramework/Overview/LoaderOverviewPanel";
import { PolicyViolationDetailsCard } from "../llm/LLMModel/PolicyViolationDetailsCard";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import {
  deleteLoaderAppSlice,
  fetchLoaderAppDetailsSlice,
  fetchLoaderGraphSlice,
  fetchLoaderOverviewSlice,
  setLoaderAppDescriptionSlice,
} from "../../redux/slices/dataLoaderSlice";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import { useHistory } from "react-router-dom";

const DATA_LOADER_STR = "data-loader";

const DataLoaderDetailsPage = ({ setPath }) => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const [frameworkData, setFrameworkData] = useState<any>({});
  const [collapsibleTreeData, setCollapsibleTreeData] = useState<any>({});

  const [nodeHeight, setNodeHeight] = useState(0);
  const dispatch = useDispatch<any>();
  const params = useParams();
  const theme = useTheme();
  const history = useHistory();

  const styles = {
    tabLabel: {
      paddingX: theme.spacing(1),
    },
    langchainDetailsCard: {
      display: "flex",
      gap: theme.spacing(4),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      height: "auto",
      transition: "all 0.5s",
    },
    langchainFramework: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      height: "100%",
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    tab: {
      marginTop: theme.spacing(1.5),
    },
  };

  const frameworkTabs = [
    {
      value: "overview",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Overview
        </Typography>
      ),
    },
    {
      value: "policyViolations",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Policy Violations
        </Typography>
      ),
    },
  ];

  const getDataLoaderAppDetails = async () => {
    const payload = {
      dataLoaderId: params.id,
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchLoaderAppDetailsSlice(payload));
    if (response.payload) {
      setFrameworkData(response.payload);
    }
    const graphResponse: any = {};
    const networkData = await dispatch(fetchLoaderGraphSlice(payload));
  };

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  useEffect(() => {
    getDataLoaderAppDetails();
  }, []);

  const getLoaderData = async () => {
    const dataLoaderId = params.id;
    const payload = {
      dataLoaderId,
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchLoaderOverviewSlice(payload));
    if (response.payload) {
      return response.payload;
    }
    return { overview: {}, data: {} };
  };

  const handleDelete = async (appName) => {
    const payload = {
      customerId: getCustomerId(),
      dataLoaderId: params.id,
    };
    const resp = await dispatch(deleteLoaderAppSlice(payload));
    if (resp?.payload) {
      Toaster(
        TOASTER_TYPES.SUCCESS,
        `Application ${appName} successfully deleted!`
      );
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp?.payload?.message);
    }
    history.push("/");
  };

  const setDescription = async (description) => {
    const payload = {
      customerId: getCustomerId(),
      id: params.id,
      description,
    };
    return await dispatch(setLoaderAppDescriptionSlice(payload));
  };

  return (
    <Box sx={styles.langchainFramework}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Data Loaders
        </Link>
        <Typography variant="caption" sx={styles.activeLink}>
          {frameworkData?.frameworkSummary?.name}
        </Typography>
      </Breadcrumbs>
      <LLMFrameworkDetails
        item="loader"
        frameworkData={frameworkData}
        onDeleteApp={handleDelete}
        setDescription={setDescription}
      />
      <Box sx={styles.tab}>
        <LLMTabs
          tabs={frameworkTabs}
          activeTab={selectedTab}
          onTabChange={(event, newValue) => setSelectedTab(newValue)}
        />
      </Box>
      <TabPanel value={selectedTab} index="overview" key="overview">
        <LoaderOverviewPanel
          getCardData={getLoaderData}
          page={DATA_LOADER_STR}
        />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index="policyViolations"
        key="policyViolations"
      >
        <PolicyViolationDetailsCard ticketIntegration={false} />
      </TabPanel>
    </Box>
  );
};

export default DataLoaderDetailsPage;
