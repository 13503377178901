import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from "@mui/material";
import * as d3 from "d3";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import data from "../../graph-data/countries.json";
import { MapPlotData, RegionsTooltip } from "../../types/type";
import Box from "./Box";
import Button from "./Button";
import Typography from "./Typography";
import WorldMapTooltip from "./WorldMapTooltip";

export type CountryData = {
  type: {};
  properties: {
    id: string;
    name: string;
    impacted: number;
    hasDataResidencyViolation: boolean;
    hasDataStoreRisk: boolean;
    sensitiveData: [];
    cloud?: string;
  };
  geometry: { type: string; coordinates: [number, number] };
};

type Polygon = {
  type: "Polygon";
  coordinates: number[][][];
};

export interface Props {
  countriesToPlot: MapPlotData[];
  activeTabData: {
    value: string;
    label: string;
    color: string;
    stroke: string;
  };
  activeRegion: { data: []; region: string; regionCode: string };
  setActiveRegion: Dispatch<
    SetStateAction<{ data: []; region: string; regionCode: string }>
  >;
  handleMouseOverTooltip: (cloud: string, data: [], impacted: number) => void;
  tooltipData: RegionsTooltip;
  clearTooltipData: () => void;
  isFrameworkView: boolean;
}

const WorldMap = ({
  countriesToPlot,
  activeTabData,
  activeRegion,
  setActiveRegion,
  handleMouseOverTooltip,
  tooltipData,
  isFrameworkView,
  clearTooltipData = () => {},
}: Props) => {
  const theme = useTheme();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 });
  const [regionTooltip, setRegionTooltip] = useState<string | undefined>(
    undefined
  );
  const [hoveredRegion, setHoveredRegion] = useState<string>("");

  const styles = {
    graphContainer: {
      width: "100%",
      height: "100%",
    },
    worldMapConatiner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    zoomButtonContainer: {
      display: "flex",
      alignItem: "center",
      gap: theme.spacing(1),
      position: "absolute",
      left: theme.spacing(3),
      bottom: theme.spacing(3),
    },
    zoomButton: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      minWidth: "24px",
      height: "24px",
      border: `1px solid ${theme.palette.surface40.main}`,
      color: theme.palette.surface40.main,
      padding: 0,
    },
    zoomIcon: {
      width: 16,
    },
    resetMapButton: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      border: `1px solid ${theme.palette.surface40.main}`,
      color: theme.palette.surface40.main,
      textTransform: "none",
    },
  };

  const resetActiveRegion = () => {
    setActiveRegion({
      data: [],
      region: "All Regions",
      regionCode: "all",
    });
  };

  const getScale = () => {
    const width = window.innerWidth;
    if (width > 1400) return 130;
    if (width > 1180) return 110;
    return 90;
  };

  let zoom = d3.zoom().scaleExtent([0.25, 10]).on("zoom", handleZoom);

  function initZoom() {
    d3.select(".worldMapContainer svg").call(zoom as any);
  }

  const zoomIn = () => {
    d3.select(".worldMapContainer svg")
      .transition()
      .call(zoom.scaleBy as any, 2);
  };

  const zoomOut = () => {
    d3.select(".worldMapContainer svg")
      .transition()
      .call(zoom.scaleBy as any, 0.5);
  };

  const resetZoom = () => {
    d3.select(".worldMapContainer svg")
      .transition()
      .call(zoom.scaleTo as any, 1);
  };

  function handleZoom(e) {
    d3.selectAll(".worldMapContainer path").attr("transform", e.transform);
    d3.selectAll(".worldMapContainer circle").attr("transform", e.transform);
  }

  const draw = () => {
    const element = d3.selectAll(".worldMapContainer");
    element.selectAll("svg").remove();
    const width =
      parseFloat(d3.select(".worldMapContainer").style("width")) - 20;
    const height = 400;
    const svg = element
      .append("svg")
      .attr("width", width)
      .attr("height", `${height}px`);
    const projection = d3
      .geoMercator()
      //.geoEquirectangular()
      .scale(getScale())
      //.translate([width / 2, height / 2]);
      .translate([width / 2, height - 150]);
    const pathGenerator = d3.geoPath().projection(projection);
    const countries = data.features;

    const valueExtent = d3.extent(
      countriesToPlot,
      (d: MapPlotData) => d.properties.impacted
    );
    const radiusScale = d3
      .scaleLinear()
      .range([10, 20])
      .domain([valueExtent[0] ?? 0, valueExtent[1] ?? 0]);

    svg
      .selectAll("path")
      .data(countries)
      .enter()
      .append("path")
      .attr("class", "country")
      .attr("d", (d) => pathGenerator(d.geometry as Polygon))
      .attr("stroke", theme.palette.surface10.main)
      .attr("fill", theme.palette.surface20.main);

    svg
      .selectAll("circle")
      .data(countriesToPlot)
      .enter()
      .append("circle")
      .attr("class", "mapPlotCircle")
      .attr("cx", (d: MapPlotData) => {
        const res = projection([
          d.geometry.coordinates[0],
          d.geometry.coordinates[1],
        ]);
        return res ? res[0] : 0;
      })
      .attr("cy", (d: MapPlotData) => {
        const res = projection([
          d.geometry.coordinates[0],
          d.geometry.coordinates[1],
        ]);
        return res ? res[1] : 0;
      })
      .attr("r", (d) => {
        const radius = radiusScale(d.properties.impacted);
        return radius;
      })
      .attr("fill", (d) =>
        d.properties.id === hoveredRegion
          ? theme.palette.surface100.main
          : d.properties.hasDataResidencyViolation
          ? theme.palette.critical.main
          : activeTabData?.color
      )
      .attr("stroke-width", "1px")
      .attr("stroke", theme.palette.surface20.main)
      .on("click", (event, d) => {
        setActiveRegion({
          regionCode: d.properties.region,
          data: d.properties.sensitiveData,
          region: d.properties.name,
        });
      })
      .on("mouseover", (event, d: MapPlotData) => {
        setHoveredRegion(d.properties.id);
        const xPos = event.clientX;
        const yPos = event.clientY;
        setTooltipCoordinates({ x: xPos, y: yPos });
        setIsTooltipVisible(true);
        setRegionTooltip(`${d.properties.name} (${d.properties.region})`);
        handleMouseOverTooltip(
          d.properties?.cloud || "",
          d.properties.sensitiveData,
          d.properties.impacted
        );
      })
      .on("mouseout", (event, d: MapPlotData) => {
        setHoveredRegion("");
        setIsTooltipVisible(false);
        clearTooltipData();
      });

    // Implement pinch-zoom behavior
    const zoom = d3.zoom().on("zoom", (event) => {
      svg
        .selectAll(".worldMapContainer path")
        .attr("transform", event.transform);
      svg
        .selectAll(".worldMapContainer circle")
        .attr("transform", event.transform);
    });

    initZoom();
    svg.call(zoom as any);
  };

  useEffect(() => {
    if (activeRegion !== undefined) {
      d3.select(".worldMapContainer")
        .select("svg")
        .selectAll("circle")
        .data(countriesToPlot)
        .attr("fill", (d) =>
          d.properties.id === activeRegion.regionCode ||
          d.properties.id === hoveredRegion
            ? theme.palette.surface100.main
            : d.properties.hasDataResidencyViolation
            ? theme.palette.critical.main
            : activeTabData?.color
        )
        .attr("stroke-width", "1px")
        .attr("filter", (d) =>
          d.properties.id === activeRegion.regionCode
            ? "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.40))"
            : "none"
        )
        .on("click", (event, d: any) => {
          if (activeRegion.regionCode !== d.properties.id) {
            setActiveRegion({
              regionCode: d.properties.region,
              data: d.properties.sensitiveData,
              region: d.properties.name,
            });
          } else {
            resetActiveRegion();
          }
        });
    }
  }, [activeRegion, hoveredRegion]);

  useEffect(() => {
    draw();
  }, [countriesToPlot]);

  return (
    <Box sx={styles.graphContainer}>
      <div className="worldMapContainer" style={styles.worldMapConatiner}></div>
      {isTooltipVisible && (
        <WorldMapTooltip
          region={regionTooltip || ""}
          recordsTitle={
            !isFrameworkView
              ? "Data Type & Records At Risk"
              : "Restricted Messages"
          }
          recordList={tooltipData}
          tooltipCoordinates={tooltipCoordinates}
          isTooltipVisible={isTooltipVisible}
          isFrameworkView={isFrameworkView}
        />
      )}
      <Box sx={styles.zoomButtonContainer}>
        <Button sx={styles.zoomButton} onClick={zoomIn}>
          <AddIcon sx={styles.zoomIcon} />
        </Button>
        <Button sx={styles.zoomButton} onClick={zoomOut}>
          <RemoveIcon sx={styles.zoomIcon} />
        </Button>
        <Button sx={styles.resetMapButton} onClick={resetActiveRegion}>
          <Typography variant="tooltip">Reset to Default</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default WorldMap;
