import Box from "../../common/Box";
import Divider from "../../common/Divider";
import KeyValueBlock from "../../common/KeyValueBlock";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import LangchainIcon from "../../../assets/img/langchainIcon.svg";
import LangchainHexagonIcon from "../../../assets/img/langchainHexagonIcon.svg";
import AWSIcon from "../../../assets/img/awsIcon.svg";
import GCPIcon from "../../../assets/img/gcp.svg";
import DesktopIcon from "../../../assets/img/desktopIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  convertUTCToLocal,
  getDateTime,
  getDifferenceInDays,
  getImageForNode,
  getLoaderIcon,
} from "../../../utils/commonUtils";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import { DeleteApplicationDialog } from "./DeleteApplicationDialog";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../common/Accordion";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddDescriptionDialog from "../AddDescriptionDialog";
import { Menu } from "../../common/Menu";
import { MenuItem } from "../../common/Select";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ViewMoreBlock from "../../common/ViewMoreBlock";
import PebbloIcon from "../../../assets/img/pebblo-logo.png";
import Chip from "../../common/Chip";
import { setAppDescriptionSlice } from "../../../redux/slices/appSlice";

const NUM_OF_DEFAULT_CHARACTERS = 100;

const LLMFrameworkDetails = ({
  item,
  frameworkData,
  onDeleteApp,
  setDescription,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const history = useHistory();
  const [showFrameworkDetails, setShowFrameworkDetails] = useState(false);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [isAddDescription, setIsAddDescription] = useState(false);
  const [isShowDescription, setIsShowDescription] = useState(false);
  const [frameworkDescription, setFrameworkDescription] = useState("");
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const isApp = item === "application";

  const styles = {
    langchainTitle: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "center",
    },
    langchainDetails: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      gap: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    langchainDetailsCard: {
      display: "flex",
      gap: theme.spacing(4),
    },
    detailsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      flexShrink: 0,
    },
    frameworkDetails: {
      minWidth: "25%",
      width: "25%",
    },
    summaryDetailsRow: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      columnGap: theme.spacing(2),
    },
    instanceDetailsRow: {
      display: "grid",
      gridTemplateColumns: "25% 25% 25% 25%",
      gridGap: "10px",
      width: "100%",
    },
    instanceDetails: {
      minWidth: "60%",
    },
    instanceDetailsHeader: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    divider: {
      height: "13px",
      margin: "auto",
      borderWidth: "1px",
    },
    miscDetails: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "end",
    },
    useTimeStamp: {
      display: "flex",
      gap: theme.spacing(1),
    },
    lastUsedText: {
      whiteSpace: "nowrap",
    },
    showHideDetails: {
      overflow: "hidden",
      height: showFrameworkDetails ? "auto" : "0%",
      display: showFrameworkDetails ? "block" : "none",
      transition: "all 0.5s",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    frameworkDetailsHeader: {
      display: "flex",
      alignItems: "top",
      width: "fit-content",
    },
    deleteFramework: {
      color: theme.palette.critical.main,
    },
    frameworkInitialDetail: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    frameworkHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    accordionSummary: {
      width: "100%",
    },
    icon: {
      color: `${theme.palette.primaryBlue.main} !important`,
      fontSize: theme.spacing(2.25),
    },
    summaryContainer: {
      display: "flex",
      gap: theme.spacing(16),
      justifyContent: "space-between",
    },
    actionsContainer: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    summary: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    summaryText: {
      fontSize: "13px",
      fontWeight: 500,
    },
    actions: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    menuIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    iconButton: {
      height: "fit-content",
      width: "fit-content",
    },
    description: {
      display: "inline",
    },
    descriptionText: {
      marginRight: theme.spacing(0.5),
      wordBreak: "break-word",
    },
    appSubHeading: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    pebbloChip: {
      borderRadius: "4px",
      paddingY: "2px",
      height: "auto",
      backgroundColor: theme.palette.surfaceFloatingWindow.main,
      "& .MuiChip-avatar": {
        width: "16px",
        height: "16px",
      },
    },
    graphContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  };

  const getCloudTypeIcon = () => {
    const cloudType = frameworkData?.providerInfo?.cloudType;
    switch (cloudType) {
      case "gcp":
        return GCPIcon;
      case "aws":
        return AWSIcon;
      default:
        return DesktopIcon;
    }
  };

  const getLastUsedDate = (lastUsedDate) => {
    const convertedDate = lastUsedDate ? convertUTCToLocal(lastUsedDate) : "";
    if (convertedDate) {
      return `${getDifferenceInDays(
        new Date(),
        new Date(convertedDate.toDate())
      )} | ${convertedDate.format("hh:mm A")}`;
    }
    return "-";
  };

  const vectorDB = frameworkData?.vectorDB;
  const pebbloClient = frameworkData?.pebbloClientVersion;
  const pebbloServer = frameworkData?.pebbloServerVersion;

  const handleAddDescriptionForFramework = async (val) => {
    const resp = await setDescription(val);
    if (resp?.payload?.message) {
      Toaster(
        TOASTER_TYPES.SUCCESS,
        frameworkDescription
          ? "You have successfully updated the description!"
          : "You have successfully added the description!"
      );
      setFrameworkDescription(val);
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp?.payload?.error?.message);
    }
    setIsAddDescription(false);
  };

  const handleShowDescription = () => setIsShowDescription((prev) => !prev);

  const getModelName = () => {
    if (frameworkData?.models?.vendor) {
      return (
        frameworkData?.models?.vendor + " - " + frameworkData?.models?.name
      );
    }
    return "-";
  };

  const modelName = getModelName();

  useEffect(() => {
    const desc = frameworkData?.frameworkSummary?.description || "";
    if (desc) {
      setFrameworkDescription(desc);
    }
  }, [frameworkData]);

  return (
    <React.Fragment>
      <Accordion>
        <Box sx={styles.frameworkHeader}>
          <Box sx={styles.langchainTitle}>
            <img src={LangchainHexagonIcon} alt="langchain" />
            <Box sx={styles.frameworkInitialDetail}>
              <Box sx={styles.langchainTitle}>
                <Typography>{frameworkData?.frameworkSummary?.name}</Typography>
                <img src={getCloudTypeIcon()} alt="aws" width={20} />
              </Box>
              <Box sx={styles.appSubHeading}>
                {pebbloClient && (
                  <Chip
                    sx={styles.pebbloChip}
                    avatar={
                      <img
                        src={PebbloIcon}
                        alt="pebblo"
                        width="14"
                        height={14}
                      />
                    }
                    label={
                      <Typography variant="caption">{`Pebblo Client V${pebbloClient}`}</Typography>
                    }
                  />
                )}
                <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}
                >{`Last Used: ${
                  frameworkData?.lastused
                    ? `${getLastUsedDate(frameworkData?.lastused)}`
                    : "-"
                }`}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.actions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={styles.icon} />}
              onClick={() => setShowFrameworkDetails(!showFrameworkDetails)}
            >
              <TextButton sx={styles.lastUsedText}>
                {`${showFrameworkDetails ? "Hide" : "Show"} Details`}
              </TextButton>
            </AccordionSummary>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.divider}
            />
            <IconButton
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              sx={styles.iconButton}
            >
              <MoreVertIcon sx={styles.menuIcon} />
            </IconButton>
            <Menu
              open={isMenuOpen}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
            >
              <MenuItem>
                <TextButton
                  startIcon={<DeleteOutlineOutlined />}
                  color="error"
                  onClick={() => setIsDeleteDialog(true)}
                >
                  Delete
                </TextButton>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <AccordionDetails>
          <Box sx={styles.langchainDetails}>
            <Box sx={styles.summaryContainer}>
              <Box sx={styles.summary}>
                <Typography
                  color={theme.palette.surface80.main}
                  sx={styles.summaryText}
                >
                  Description
                </Typography>
                <Box sx={styles.description}>
                  <ViewMoreBlock
                    value={frameworkDescription}
                    length={NUM_OF_DEFAULT_CHARACTERS}
                  />
                </Box>
              </Box>
              <Box sx={{ ...styles.detailsSection, ...styles.miscDetails }}>
                <TextButton
                  startIcon={
                    frameworkDescription ? <EditIcon /> : <PostAddIcon />
                  }
                  onClick={() => setIsAddDescription(true)}
                >
                  {frameworkDescription ? "Update" : "Add"} Description
                </TextButton>
              </Box>
            </Box>
            <Box sx={styles.langchainDetailsCard}>
              <Box
                sx={{ ...styles.detailsSection, ...styles.frameworkDetails }}
              >
                <Box sx={styles.frameworkDetailsHeader}>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    Framework Details
                  </Typography>
                </Box>
                <Box sx={styles.summaryDetailsRow}>
                  <KeyValueBlock
                    label="Name"
                    value={frameworkData?.frameworkSummary?.name}
                    valueIcon={
                      <img src={LangchainIcon} alt="langchain" width={20} />
                    }
                  />
                  <KeyValueBlock
                    label="Version"
                    value={frameworkData?.frameworkSummary?.version}
                  />
                </Box>

                <Box sx={styles.summaryDetailsRow}>
                  <KeyValueBlock
                    label="VectorDB"
                    value={`${vectorDB || ""} ${
                      frameworkData?.vectorDBVersion || "-"
                    }`}
                    valueIcon={
                      vectorDB && (
                        <img
                          src={getImageForNode(vectorDB)?.imgSrc}
                          alt=""
                          width={14}
                        />
                      )
                    }
                  />
                  <KeyValueBlock
                    label="VectorDB Location"
                    value={frameworkData?.vectorDBLocation}
                  />
                </Box>
                <Box sx={styles.summaryDetailsRow}>
                  <KeyValueBlock
                    label="Embedding Model"
                    value={frameworkData?.embeddingModel || ""}
                  />
                  <KeyValueBlock
                    label="LLM Model"
                    value={modelName}
                    valueIcon={
                      modelName !== "-" ? (
                        <img
                          src={getLoaderIcon(frameworkData?.models?.vendor)}
                          alt="model"
                          width={20}
                        />
                      ) : null
                    }
                  />
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ ...styles.detailsSection, ...styles.instanceDetails }}>
                <Box
                  sx={{
                    ...styles.frameworkDetailsHeader,
                    ...styles.instanceDetailsHeader,
                  }}
                >
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    Instance Details
                  </Typography>
                  <Divider orientation="vertical" sx={styles.divider} />
                  <Typography
                    variant="caption"
                    color={theme.palette.surface40.main}
                  >
                    {frameworkData?.providerInfo?.region || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.instanceDetailsRow}>
                  <KeyValueBlock
                    label="Account ID"
                    value={frameworkData?.providerInfo?.accountId}
                  />
                  <KeyValueBlock
                    label="Instance ID"
                    value={frameworkData?.providerInfo?.instanceId}
                  />
                  <KeyValueBlock
                    label="Created At"
                    value={
                      frameworkData?.createdAt
                        ? getDateTime(
                            convertUTCToLocal(frameworkData?.createdAt)
                          )
                        : "-"
                    }
                  />
                  {pebbloServer && (
                    <KeyValueBlock
                      label="Pebblo Server Version"
                      value={pebbloServer}
                    />
                  )}
                </Box>

                <Box sx={styles.instanceDetailsRow}>
                  <KeyValueBlock
                    label="Runtime"
                    value={frameworkData?.instanceDetails?.runtime || ""}
                  />
                  <KeyValueBlock
                    label="Host"
                    value={frameworkData?.instanceDetails?.host || ""}
                  />
                  <KeyValueBlock
                    label="Path"
                    value={frameworkData?.instanceDetails?.path || ""}
                  />
                </Box>
                <Box sx={styles.instanceDetailsRow}>
                  <KeyValueBlock
                    label="IP"
                    value={frameworkData?.instanceDetails?.ip || ""}
                  />
                  <KeyValueBlock
                    label="Language"
                    value={frameworkData?.instanceDetails?.language}
                  />
                  <KeyValueBlock
                    label="Language Version"
                    value={frameworkData?.instanceDetails?.languageVersion}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <DeleteApplicationDialog
        appName={frameworkData?.frameworkSummary?.name}
        isOpen={isDeleteDialog}
        handleClose={() => setIsDeleteDialog(false)}
        onDelete={() => {
          onDeleteApp(frameworkData?.frameworkSummary?.name);
          setIsDeleteDialog(false);
        }}
      />
      <AddDescriptionDialog
        isOpen={isAddDescription}
        onClose={() => setIsAddDescription(false)}
        handleSubmit={(val) => handleAddDescriptionForFramework(val)}
        description={frameworkDescription || null}
      />
    </React.Fragment>
  );
};

export default LLMFrameworkDetails;
