import { useTheme } from "@mui/material";
import { getFirstNValue, getJoinedString } from "../../utils/commonUtils";
import Box from "../common/Box";
import CardWithAction from "./CardWithAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import { useEffect, useMemo, useState } from "react";
import { fetchAppsListSlice } from "../../redux/slices/appSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import NoData from "../common/NoData";
import Loader from "../common/Loader";
import Tooltip from "../common/Tooltip";
import { useHistory } from "react-router-dom";
import {
  ALL_APPLICATIONS_PAGE,
  APPLICATION_DETAILS,
} from "../../routes/constants";
import { ListAppAPI } from "./APIResponseType";

const APP_RETRIEVAL_COUNT = 5;

const ApplicationsCard = () => {
  const [applications, setApplications] = useState<ListAppAPI[]>([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const theme = useTheme();
  const styles = {
    noDataCell: {
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  };

  const redirectToAppDetailsPage = (appId: string) => {
    history.push(APPLICATION_DETAILS + appId);
  };

  const redirectToAllAppsPage = (tab) => {
    history.push(ALL_APPLICATIONS_PAGE);
  };

  const cardData = useMemo(
    () => ({
      applications: {
        title: "Applications",
        children: (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Policies Violated</TableCell>
                  <TableCell align="right">Retrievals</TableCell>
                  <TableCell align="right">Active Users</TableCell>
                  <TableCell>Labels</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingData ? (
                  applications?.length > 0 ? (
                    applications.map((app) => {
                      return (
                        <TableRow
                          onClick={() => redirectToAppDetailsPage(app.id)}
                        >
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {app.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {app?.policyViolated || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {app?.retrievals || "0"}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {app?.active_users}
                            </Typography>
                          </TableCell>
                          <TableCell
                            width={app?.labels?.length ? "40%" : "auto"}
                          >
                            <Tooltip title={getJoinedString(app.labels, ", ")}>
                              <div>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.surface80.main}
                                >
                                  {getFirstNValue(app.labels, 1)}
                                </Typography>
                              </div>
                            </Tooltip>{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableCell colSpan={5} sx={styles.noDataCell}>
                      <NoData customText="No data found" />
                    </TableCell>
                  )
                ) : (
                  <TableCell colSpan={5} sx={styles.noDataCell}>
                    <Loader height="150px" />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ),
      },
    }),
    [applications]
  );

  const fetchAppList = async () => {
    setIsFetchingData(true);
    const payload = {
      customerId: getCustomerId(),
      count: APP_RETRIEVAL_COUNT,
    };
    const response = await dispatch(fetchAppsListSlice(payload));
    if (response?.payload) {
      setApplications(response.payload);
    } else {
      setApplications([]);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    fetchAppList();
  }, []);

  return (
    <Box>
      <CardWithAction
        cardData={cardData}
        buttonTitle="View All"
        onClick={redirectToAllAppsPage}
      />
    </Box>
  );
};

export default ApplicationsCard;
