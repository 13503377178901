import { useTheme } from "@mui/material";
import Button from "../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import Typography from "../common/Typography";

const DeletePolicy = ({ policyName, open, onClose, onDelete }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle handleClose={onClose}>Delete Policy?</DialogTitle>
      <DialogContent>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          It will permanently delete the policy "{policyName}"
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePolicy;
