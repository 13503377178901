import { useEffect, useState } from "react";
// import Logo from "../../assets/img/new-theme/logo.svg";
import Logo from "../../assets/img/daxa-assets/daxa-icon.svg";
import { ReactComponent as DaxaTitle } from "../../assets/img/daxa-assets/daxa-title.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Sidebar.scss";
import { ReactComponent as Home } from "../../assets/img/new-theme/sidebar/home.svg";
import { ReactComponent as Data } from "../../assets/img/new-theme/sidebar/data.svg";
import { ReactComponent as Issues } from "../../assets/img/new-theme/sidebar/issues.svg";
import { ReactComponent as Cloud } from "../../assets/img/new-theme/sidebar/cloud.svg";
import { ReactComponent as Reports } from "../../assets/img/new-theme/sidebar/report.svg";
import { ReactComponent as Accounts } from "../../assets/img/new-theme/sidebar/accounts.svg";
import { ReactComponent as Integrations } from "../../assets/img/new-theme/sidebar/integrations.svg";
import { ReactComponent as Policy } from "../../assets/img/new-theme/sidebar/policy.svg";
import { ReactComponent as UserManagement } from "../../assets/img/new-theme/sidebar/user_management.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/img/new-theme/left-circle-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/img/new-theme/right-circle-arrow.svg";
import {
  expandSidebar,
  minimizeSidebar,
} from "../../redux/slices/dashboardSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useLocation } from "react-router-dom";
import CustomTooltip from "../custom-tooltip/CustomTooltip";
import { useDispatch } from "react-redux";
import Box from "../common/Box";
const Sidebar = ({
  setTab,
  expandedSidebar,
  setExpandedSidebar,
  tab,
  path,
  setPath,
}) => {
  const [admin, setAdmin] = useState(false);
  const location = useLocation();
  const accountsAvailable = useSelector(
    (state) => state.account.accountAvailable
  );
  const dispatch = useDispatch();

  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );

  const toggleSidebar = () => {
    if (expandedSidebar) {
      dispatch(minimizeSidebar());
    } else {
      dispatch(expandSidebar());
    }
  };

  const riskStatCompleted =
    firstAccountStatus && firstAccountStatus.first_acct_risk_complete;

  // useEffect(() => {
  //   if (path === "/checklist") {
  //     setTab(3);
  //   } else if (
  //     path.includes("settings") &&
  //     localStorage.getItem("role") === "ADMIN"
  //   ) {
  //     setAdmin(true);
  //   } else {
  //     setAdmin(false);
  //   }
  // }, [path]);

  // useEffect(() => {
  //   console.log(expandedSidebar);
  // }, [expandedSidebar]);

  return (
    <div
      id="cd-sidebar"
      className={`${
        expandedSidebar ? "expanded-sidebar" : "collapsed-sidebar"
      }`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div className={`${expandedSidebar ? "cd-logo" : "cd-logo"} px-4`}>
          <Link to={accountsAvailable ? "/" : "#"}>
            <div className="d-flex align-items-center">
              <div>
                <img src={Logo} alt="Logo" height={32} />
              </div>

              <div
                className={`ml-3 daxa-logo-title ${
                  expandedSidebar ? "visible" : "hidden"
                }`}>
                <DaxaTitle className="w-16" height="100%" />
              </div>
            </div>
          </Link>
        </div>
        <div className="position-relative d-flex flex-column justify-content-between h-100 px-3">
          <Box
            className={`position-absolute arrow-icon ${
              expandedSidebar ? "right-aligned" : "centered"
            }`}>
            {expandedSidebar ? (
              <LeftArrowIcon
                className="cursor-pointer "
                onClick={() => toggleSidebar()}
              />
            ) : (
              <RightArrowIcon
                className="cursor-pointer"
                onClick={() => toggleSidebar()}
              />
            )}
          </Box>
          <div className="sidebar-menu-container">
            <CustomTooltip
              component={
                <Link to="/">
                  <div
                    className={`d-flex mt-5 align-items-center sidebar-menuItem ${
                      location.pathname === "/"
                        ? expandedSidebar
                          ? "sidebar-menu-selected-extended"
                          : "sidebar-menu-selected"
                        : expandedSidebar
                        ? "sidebar-menu-extended"
                        : "sidebar-menu"
                    }`}>
                    <Home className="flex-shrink-0" />
                    <span className="sidebar-menu-title ml-3">Home</span>
                  </div>
                </Link>
              }
              tooltipTitle="Home"
            />

            <CustomTooltip
              component={
                <Link to="/settings/policy-settings">
                  <div
                    className={`d-flex align-items-center sidebar-menuItem ${
                      location.pathname === "/settings/policy-settings"
                        ? expandedSidebar
                          ? "sidebar-menu-selected-extended"
                          : "sidebar-menu-selected"
                        : expandedSidebar
                        ? "sidebar-menu-extended"
                        : "sidebar-menu"
                    }`}>
                    <Policy className="flex-shrink-0" />
                    <span className="sidebar-menu-title ml-3">Policy</span>
                  </div>
                </Link>
              }
              tooltipTitle="Policy Settings"
            />
            <CustomTooltip
              component={
                <Link to="/account">
                  <div
                    className={`d-flex align-items-center sidebar-menuItem ${
                      location.pathname === "/account"
                        ? expandedSidebar
                          ? "sidebar-menu-selected-extended"
                          : "sidebar-menu-selected"
                        : expandedSidebar
                        ? "sidebar-menu-extended"
                        : "sidebar-menu"
                    }`}>
                    <Accounts className="flex-shrink-0" />
                    <span className="sidebar-menu-title ml-3">Accounts</span>
                  </div>
                </Link>
              }
              tooltipTitle="Accounts"
            />

            <CustomTooltip
              component={
                <Link to="/settings/integrations">
                  <div
                    className={`d-flex align-items-center sidebar-menuItem ${
                      location.pathname === "/settings/integrations"
                        ? expandedSidebar
                          ? "sidebar-menu-selected-extended"
                          : "sidebar-menu-selected"
                        : expandedSidebar
                        ? "sidebar-menu-extended"
                        : "sidebar-menu"
                    }`}>
                    <Integrations className="flex-shrink-0" />
                    <span className="sidebar-menu-title ml-3">
                      Integrations
                    </span>
                  </div>
                </Link>
              }
              tooltipTitle="Integrations"
            />
            <CustomTooltip
              component={
                <Link to="/settings/admin">
                  <div
                    className={`d-flex align-items-center sidebar-menuItem ${
                      location.pathname === "/settings/admin"
                        ? expandedSidebar
                          ? "sidebar-menu-selected-extended"
                          : "sidebar-menu-selected"
                        : expandedSidebar
                        ? "sidebar-menu-extended"
                        : "sidebar-menu"
                    }`}>
                    <UserManagement className="flex-shrink-0" />
                    <span className="sidebar-menu-title ml-3">Users</span>
                  </div>
                </Link>
              }
              tooltipTitle=" User Management"
            />
          </div>
        </div>

        <div className=""></div>
      </div>
    </div>
  );
};

export default Sidebar;
