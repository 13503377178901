import { useTheme } from "@mui/material";
import { ReactElement, useEffect, useMemo, useState } from "react";
import BarRect from "../common/BarRect";
import Box from "../common/Box";
import CardWithAction from "./CardWithAction";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchFindingsSlice } from "../../redux/slices/dashboardSlice";
import Typography from "../common/Typography";
import NoData from "../common/NoData";
import { getSumOfValues } from "../../utils/commonUtils";
import { CardDataObj } from "./type";
import { useHistory } from "react-router-dom";
import { ALL_FINDINGS_PAGE } from "../../routes/constants";

const FETCH_DATA_LIMIT = 5;
const FINDINGS = "findings";
const DOCS_WITH_FINDINGS = "docs-with-findings";

const TabData = ({ findingsData, findingsObj, cardText }) => {
  const theme = useTheme();
  const styles = {
    box: {
      height: "100%",
    },
    barContainer: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  };
  return (
    <Box sx={styles.box}>
      {findingsData && findingsData?.length ? (
        <>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.barContainer}
          >
            {cardText}
          </Typography>
          <Box>
            {findingsData.map((finding, index) => {
              return (
                <BarRect
                  barLabel={finding}
                  barValue={findingsObj[finding] || 0}
                  totalValue={getSumOfValues(Object.values(findingsObj!) || [])}
                  barFaceColor={theme.palette.lightMagenta.main}
                  barBackColor={theme.palette.surface20.main}
                  isLastBar={findingsData.length - 1 === index}
                  index={index}
                  xLabel="Number of Snippets"
                  onClick={() => {}}
                />
              );
            })}
          </Box>
        </>
      ) : (
        <NoData customText="No Data Found" />
      )}
    </Box>
  );
};

const FindingsCard = () => {
  const [cardData, setCardData] = useState<CardDataObj | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const styles = {
    box: {
      height: "100%",
    },
  };

  const fetchFindingsData = async () => {
    setIsFetching(true);
    const payload = {
      customerId: getCustomerId(),
      count: FETCH_DATA_LIMIT,
    };
    const response = await dispatch(fetchFindingsSlice(payload));
    const findings = response?.payload;
    const findingsData =
      findings && findings?.findings ? Object.keys(findings?.findings) : [];
    const docsWithFindings =
      findings && findings?.documents_with_findings
        ? Object.keys(findings?.documents_with_findings)
        : [];
    const cardTabs: CardDataObj = {
      findings: {
        title: "Findings",
        children: (
          <TabData
            findingsData={findingsData}
            findingsObj={findings?.findings || []}
            cardText="Top 5 Findings with most Snippets"
          />
        ),
      },
      "docs-with-findings": {
        title: "Documents With Findings",
        children: (
          <TabData
            findingsData={docsWithFindings}
            findingsObj={findings?.documents_with_findings || []}
            cardText="Top 5 Documents with most Snippets"
          />
        ),
      },
    };
    setCardData(cardTabs);
    setIsFetching(false);
  };

  const redirectToDetailsPage = (tab) => {
    history.push(`${ALL_FINDINGS_PAGE}?tab=${tab}`);
  };

  useEffect(() => {
    fetchFindingsData();
  }, []);

  return (
    <Box sx={styles.box}>
      <CardWithAction
        cardData={cardData}
        buttonTitle="View All"
        onClick={redirectToDetailsPage}
        isLoading={isFetching}
      />
    </Box>
  );
};

export default FindingsCard;
