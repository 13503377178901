import { useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import { useHistory } from "react-router-dom";
import Bar from "../common/Bar";
import {
  fetchFrameworksSlice,
  fetchLLMModelsListingSlice,
} from "../../redux/slices/dashboardSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import Loader from "../common/Loader";
import NoData from "../common/NoData";
import { getLoaderIcon, getMaxValue } from "../../utils/commonUtils";
import Tooltip from "../common/Tooltip";
import CardWithAction from "./CardWithAction";

const ModelAppListCard = () => {
  const [selectedTab, setSelectedTab] = useState("framework");
  const [models, setModels] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardContainer: {
      maxHeight: "300px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    cardHeader: {
      paddingX: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    modelName: {
      marginLeft: theme.spacing(1),
    },
    modelIconName: {
      display: "flex",
    },
    tabPanel: {
      flex: 1,
      overflow: "hidden",
      height: "100%",
      display: "flex",
    },
    tableContainer: {
      maxHeight: "100%",
      overflow: "auto",
      flexShrink: 0,
    },
    langchainCell: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "start",
    },
    langchainVersion: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    barCell: {
      width: "150px",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    tableCell: {
      "&.MuiTableCell-root": {
        paddingX: theme.spacing(1.5),
        border: "none",
      },
    },
    whiteSpaceNoWrap: {
      whiteSpace: "nowrap",
    },
    tabPanelWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    truncate: {
      width: theme.spacing(17),
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };

  const tabList = [
    { label: "Frameworks", value: "framework" },
    { label: "LLM", value: "llm" },
  ];

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const getFrameworksData = async (payload) => {
    setIsLoading(true);
    const res = await dispatch(fetchFrameworksSlice(payload));
    if (res.payload) {
      setFrameworks(res.payload.data);
    } else {
      setFrameworks([]);
    }
    setIsLoading(false);
  };

  const getModelsData = async (payload) => {
    setIsLoading(true);
    const res = await dispatch(fetchLLMModelsListingSlice(payload));
    if (res.payload) {
      setModels(res.payload.data);
    } else {
      setModels([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const payload = {
      customerId: getCustomerId(),
    };
    getModelsData(payload);
  }, []);

  const navigateToLLM = (datastoreId, assetId) => {
    if (datastoreId && assetId) {
      history.push(`/llm/${datastoreId}/${assetId.replace("/", "_")}`);
    }
  };

  const cardData = useMemo(
    () => ({
      users: {
        title: "LLMs",
        children: (
          <Box sx={styles.tabPanelWrapper}>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableCell sx={styles.tableCell}>Name</TableCell>
                  <TableCell sx={styles.tableCell}>Policy Violations</TableCell>
                  <TableCell sx={styles.tableCell}>Status</TableCell>
                  <TableCell sx={styles.tableCell}>Category</TableCell>
                  <TableCell sx={styles.tableCell}>Labels</TableCell>
                </TableHead>
                {!isLoading ? (
                  <TableBody>
                    {models?.length > 0 ? (
                      models?.map((model: any) => {
                        return (
                          <TableRow
                            key={model?.id}
                            onClick={() =>
                              navigateToLLM(model?.datastoreId, model?.assetId)
                            }
                          >
                            <TableCell sx={styles.tableCell}>
                              <Box sx={styles.modelIconName}>
                                <img
                                  src={getLoaderIcon(model.name)}
                                  alt=""
                                  height={16}
                                />
                                <Tooltip title={model?.name}>
                                  <div>
                                    <Typography
                                      variant="caption"
                                      color={theme.palette.surface60.main}
                                      sx={{
                                        ...styles.modelName,
                                        ...styles.truncate,
                                      }}
                                    >
                                      {model?.name}
                                    </Typography>
                                  </div>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{ ...styles.barCell, ...styles.tableCell }}
                            >
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                {model?.policyViolations || 0}
                              </Typography>
                              <Bar
                                isLastBar={false}
                                xLabel={""}
                                barBackColor={theme.palette.surface20.main}
                                barFaceColor={theme.palette.lightMagenta.main}
                                rectWidth={
                                  model?.policyViolations > 0
                                    ? ((model?.policyViolations || 0) /
                                        getMaxValue(
                                          "policyViolations",
                                          models
                                        )) *
                                      100
                                    : 0
                                }
                                index={model?.name}
                                totalValue={model?.policyViolations || 0}
                                barLabel={""}
                                barValue={model?.policyViolations || 0}
                                type={""}
                              />
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                {model?.status || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                {capitalizeFirstLetter(model?.category)}
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                                align="center"
                              >
                                -
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableCell colSpan={5} borderBottom={false}>
                        <NoData customText="No data found." />
                      </TableCell>
                    )}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            {isLoading ? <Loader /> : null}
          </Box>
        ),
      },
    }),
    [models, isLoading]
  );

  return <CardWithAction cardData={cardData} onClick={(tab) => {}} />;
};

export default ModelAppListCard;
