import { useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "../common/Box";
import PageBreadcrumbs from "../common/PageBreadcrumbs";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  fetchUserAccessDataSlice,
  fetchDocAccessDataSlice,
} from "../../redux/slices/dashboardSlice";
import PageTable from "./PageTable";
import { useHistory } from "react-router-dom";

const AllUsersPage = ({ setPath }) => {
  const [accessData, setAccessData] = useState({ users: {}, docs: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    pageHeader: {
      display: "flex",
      alignItems: "center",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
  };

  const tabs = [
    {
      label: "Users",
      value: "users",
    },
    {
      label: "Documents Accessed",
      value: "documents-accessed",
    },
  ];

  const breadcrumbs = [
    {
      isLink: true,
      route: "/",
      page: "Home",
    },
    {
      isLink: false,
      page: "All User & Document Retrievals",
    },
  ];

  const usersTableCols = [
    {
      id: "user",
      value: "User",
    },
    {
      id: "retrievals",
      value: "Retrievals",
      color: theme.palette.pictonBlue.main,
    },
  ];

  const docsAccessedTableCols = [
    {
      id: "document",
      value: "Document",
    },
    {
      id: "retrievals",
      value: "Retrievals",
      color: theme.palette.pictonBlue.main,
    },
  ];

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    history.push(`${window.location.pathname}?tab=${newValue}`);
    setActiveTab(newValue);
  };

  const fetchUsersAndDocsData = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const userRes = await dispatch(fetchUserAccessDataSlice(payload));
    const docRes = await dispatch(fetchDocAccessDataSlice(payload));
    const userAccessData = userRes && userRes.payload ? userRes.payload : {};
    const docAccessData = docRes && docRes.payload ? docRes.payload : {};
    setAccessData({ users: userAccessData, docs: docAccessData });
    setIsLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get("tab");
    if (tabName) setActiveTab(tabName);
    else setActiveTab(tabs[0].value);
    setPath(window.location.pathname);
    fetchUsersAndDocsData();
  }, []);

  return (
    <Box sx={styles.page}>
      <PageBreadcrumbs breadcrumbs={breadcrumbs} />
      <Tabs value={activeTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tab {...tab} />
        ))}
      </Tabs>
      <TabPanel index={tabs[0].value} value={activeTab}>
        <PageTable
          pageTitle="List of Users"
          tableKeys={Object.keys(accessData.users)}
          tableData={accessData.users}
          tableCols={usersTableCols}
          isLoading={isLoading}
        />
      </TabPanel>
      <TabPanel index={tabs[1].value} value={activeTab}>
        <PageTable
          pageTitle="List of Accessed Documents"
          tableKeys={Object.keys(accessData.docs)}
          tableData={accessData.docs}
          tableCols={docsAccessedTableCols}
          isLoading={isLoading}
        />
      </TabPanel>
    </Box>
  );
};

export default AllUsersPage;
