import { useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Loader from "../common/Loader";
import NoData from "../common/NoData";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import CreateIssueDrawer from "../create-issue-drawer/CreateIssueDrawer";
import AWSIcon from "../../assets/img/AWS_S3.svg";
import JiraIcon from "../../assets/img/jira.svg";
import SlackIcon from "../../assets/img/slack.svg";
import Tooltip from "../common/Tooltip";
import { Pagination } from "../common/Pagination";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import { useHistory } from "react-router-dom";
import { POLICY_DETAILS } from "../../routes/constants";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import {
  accountType,
  nonSocActionType,
  non_SOC,
  socActionType,
} from "../constants/constants";
import DatastoreTicketModal from "../modal/datastore-ticket-modal/DatastoreTicketModal";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  fetchPolicyViolationsSlice,
  fetchAIPolicyViolationsSlice,
} from "../../redux/slices/policySlice";
import { convertUTCToLocal, getFirstNValue } from "../../utils/commonUtils";

type Issue = {
  id: number;
  cdDatastoreRiskScore: string;
  name: string;
  assetId: string;
  type: string;
  cloudAccountId: string;
  datastoreType: string;
  createdAt: string;
  modifiedAt: string;
  ticketIntegration: Boolean;
  statusFilter: string;
};

type TableAlign =
  | "left"
  | "center"
  | "right"
  | "justify"
  | "inherit"
  | undefined;

const IssuesTable = ({
  searchText,
  statusFilter,
}: {
  searchText: string;
  statusFilter: string;
}) => {
  const {
    policyData,
    singleJiraTicket,
    setSingleJiraTicket,
    isFetchingSingleJiraTicket,
    getJiraTicket,
    drawerData,
    notifyOnSlackBulkAction,
    selectedPolicy,
  } = useContext(AppContext);

  const [policyViolations, setPolicyViolations] = useState<any>({
    data: [],
  });
  const [tableCols, setTableCols] = useState<
    Array<{
      title: string;
      sortField?: string;
      render: (row: any) => JSX.Element;
      field?: string;
      align?: string;
    }>
  >([]);
  const [isFetchingPolicyViolations, setIsFetchingPolicyViolations] =
    useState(false);
  const [policyVoilationData, setPolicyVoilationData] = useState<Array<Issue>>(
    []
  );
  const [pageNumber, setPageNumber] = useState<Number>(1);
  const integrations = useSelector(
    (state: any) => state.integration.integrations
  );

  const [selectedIssues, setSelectedIssues] = useState<Array<Issue>>([]);
  const [ticketModal, setTicketModal] = useState(false);
  const policyViolationList = useSelector(
    (state: any) => state.dashboard.policyViolationList
  );
  const [requestPayload, setRequestPayload] = useState({
    datastoreId: "",
    policyViolationId: "",
    isSOC: false,
    url: "",
  });
  const [sortField, setSortField] = useState<
    Array<{ field: string; order: string }>
  >([]);

  const dispatch = useDispatch();
  const policyCategories = useSelector(
    (state: any) => state.dashboard.policyCategories
  );

  const theme = useTheme();
  const history = useHistory();

  const getPolicyViolations = async (requestBody) => {
    setIsFetchingPolicyViolations(true);
    const headers = {
      ...requestBody,
      customerId: getCustomerId(),
      limit: 10,
    };
    const resp = await dispatch(fetchAIPolicyViolationsSlice(headers));

    if (resp.payload) {
      setPolicyViolations(resp.payload);
    } else {
      setPolicyViolations([]);
    }
    setIsFetchingPolicyViolations(false);
  };

  const getPolicyViolationInstances = () => {
    if (selectedPolicy?.id || selectedPolicy?.policyId) {
      let sortFilter = {};
      if (sortField.length > 0) {
        for (const key of sortField) {
          sortFilter[key.field] = key.order;
        }
      }
      let actionType = "";
      if (selectedPolicy?.policyType) {
        actionType = non_SOC.includes(selectedPolicy?.policyType)
          ? nonSocActionType
          : socActionType;
      }
      const requestBody = {
        category:
          drawerData?.currentSelectedPolicy?.policyType ||
          selectedPolicy?.policyType ||
          "",
        policyId: selectedPolicy?.policyId ? selectedPolicy?.policyId : "",
        sort_order: sortFilter,
        page: pageNumber,
        status: statusFilter === "all" ? "" : statusFilter,
        actionType,
      };
      getPolicyViolations(requestBody);
    }
  };

  useEffect(() => {
    getPolicyViolationInstances();
  }, [selectedPolicy?.id, pageNumber, sortField, statusFilter]);

  useEffect(() => {
    if (policyViolations?.data?.length >= 0) {
      //policyViolations.data.forEach((item, i) => {
      //  item.id = i + 1;
      //});
      setPolicyVoilationData(policyViolations?.data);
    }
  }, [policyViolations]);

  // const colorValue = (risk: string) => {
  //   return risk === "Low"
  //     ? theme.palette.success.main
  //     : risk === "High"
  //     ? theme.palette.warning.main
  //     : theme.palette.critical.main;
  // };

  const styles = {
    tableContainer: {
      minHeight: "340px",
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    moreIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
    },
    issueName: {
      fontSize: "13px",
      lineheight: "24px",
    },
    issueId: {
      color: theme.palette.surface60.main,
    },
    impactedStores: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    lastOccur: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    sortButton: {
      marginLeft: theme.spacing(0.5),
    },
    sortIcon: (selected) => ({
      fontSize: theme.typography.body2,
      color: selected
        ? theme.palette.primaryBlue.main
        : theme.palette.surface50.main,
    }),
  };

  const llmTableCols: Array<{
    title: string;
    sortField?: string;
    render: (row: any) => JSX.Element;
    field?: string;
    align?: string;
  }> = [
    {
      title: "LLM",
      sortField: "title.keyword",
      render: (row: any) => (
        <Tooltip title={row?.title}>
          <div>
            <Typography sx={styles.issueName}>
              {row?.title}
              {/* commenting it for future requirements, for now it is not needed as user don't know about uid */}
              {/* <Typography variant="caption" component="div" sx={styles.issueId}> 
                {row?.id}
              </Typography> */}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Data Store",
      sortField: "instanceDetails.dsInfo.name.keyword",
      render: (row: any) => (
        <Tooltip title={row["assetId"]}>
          <div>
            <Typography sx={styles.issueName}>
              {row?.instanceDetails?.dsInfo?.name}
              {/* commenting it for future requirements, for now it is not needed  as user don't know about dsId */}
              {/* <Typography variant="caption" component="div" sx={styles.issueId}>
                {row?.instanceDetails?.dsInfo?.dsId}
              </Typography> */}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Category",
      sortField: "instanceDetails.dsInfo.datastore_type.keyword",
      field: "type",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.issueName}>
          {capitalizeFirstLetter(row?.instanceDetails?.dsInfo?.datastore_type)}
        </Typography>
      ),
    },
    {
      title: "Type",
      sortField: "instanceDetails.dsInfo.type.keyword",
      field: "issueName",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.issueName}>
          {/*<Box sx={styles.impactedStores}>
            <img src={AWSIcon} alt="ds" /> {row['datastoreType']}
          </Box>*/}
          {row?.instanceDetails?.dsInfo?.type}
        </Typography>
      ),
    },
    {
      title: "Last Occured",
      sortField: "instanceDetails.modifiedAt.keyword",
      field: "issueName",
      align: "",
      render: (row: any) => {
        const dateValue =
          row?.instanceDetails?.modifiedAt ||
          row?.instanceDetails?.metadata?.createdAt;
        return (
          <Typography variant="caption" sx={styles.lastOccur}>
            {moment(dateValue).format("YYYY-MM-DD")}
            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            {moment(dateValue).format("hh:mm:ss A")}
          </Typography>
        );
      },
    },
    {
      title: "Issue Status",
      sortField: "instanceDetails.status.keyword",
      field: "ticketStatus",
      align: "",
      render: (row: any) => (
        <Typography variant="caption">
          {capitalizeFirstLetter(
            statusFilter === "ticketed"
              ? row?.instanceDetails?.individualTicketInfo?.status
              : row?.instanceDetails?.status
          )}
        </Typography>
      ),
    },
    {
      title: "Actions",
      field: "actions",
      align: "left",
      render: (row: any) => {
        const ticketURL =
          row?.instanceDetails?.individualTicketInfo?.status !== "Done"
            ? row?.instanceDetails?.individualTicketInfo?.URL
            : null;
        const isSOC = row?.actionType === socActionType;
        return (
          <Box sx={styles.tableCellLast}>
            <TextButton
              onClick={(event) => {
                event.stopPropagation();
                handleActionClick(isSOC, row, ticketURL);
              }}
              disabled={
                //row?.instanceDetails?.status === "resolved" &&
                //!isSOC &&
                //!ticketURL
                true
              }
              startIcon={
                isSOC ? (
                  <img src={SlackIcon} alt="slack" />
                ) : (
                  <img src={JiraIcon} alt="jira" />
                )
              }
              sx={styles.tableCellAction}
            >
              {isSOC ? "Notify" : ticketURL ? "View Ticket" : "Create Ticket"}
            </TextButton>

            {/* commenting it for future requirements, for now it is not needed as we can't any action on data */}
            {/* <Divider
          orientation="vertical"
          flexItem
          sx={styles.tableActionDivider}
        />
        <MoreVertIcon sx={styles.moreIcon} /> */}
          </Box>
        );
      },
    },
  ];

  const frameworkTableCols: Array<{
    title: string;
    sortField?: string;
    render: (row: any) => JSX.Element;
    field?: string;
    align?: string;
  }> = [
    {
      title: "Policy Type",
      sortField: "title.keyword",
      render: (row: any) => (
        <Tooltip title={row?.title}>
          <div>
            <Typography sx={styles.issueName}>
              {row?.title}
              {/* commenting it for future requirements, for now it is not needed as user don't know about uid */}
              {/* <Typography variant="caption" component="div" sx={styles.issueId}> 
                {row?.id}
              </Typography> */}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Framework",
      sortField: "title.keyword",
      render: (row: any) => (
        <Tooltip title={row?.title}>
          <div>
            <Typography sx={styles.issueName}>
              {row?.framework}
              {/* commenting it for future requirements, for now it is not needed as user don't know about uid */}
              {/* <Typography variant="caption" component="div" sx={styles.issueId}> 
                {row?.id}
              </Typography> */}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Type",
      sortField: "type",
      render: (row: any) => (
        <Tooltip title={row["assetId"]}>
          <div>
            <Typography sx={styles.issueName}>
              {capitalizeFirstLetter(row?.type)}
              {/* commenting it for future requirements, for now it is not needed  as user don't know about dsId */}
              {/* <Typography variant="caption" component="div" sx={styles.issueId}>
                {row?.instanceDetails?.dsInfo?.dsId}
              </Typography> */}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Labels",
      sortField: "labels",
      field: "labels",
      align: "",
      render: (row: any) => (
        <Tooltip title={row?.labels?.join(", ")}>
          <div>
            <Typography sx={styles.issueName}>
              {getFirstNValue(row?.labels)}
            </Typography>
          </div>
        </Tooltip>
      ),
    },

    {
      title: "Last Violated",
      sortField: "instanceDetails.modifiedAt.keyword",
      field: "issueName",
      align: "",
      render: (row: any) => {
        const dateValue = row?.lastViolation;
        const localTime = dateValue ? convertUTCToLocal(dateValue) : "";
        const dayString = localTime ? localTime.format("YYYY-MM-DD") : "-";
        const timeString = localTime ? localTime.format("hh:mm:ss A") : "-";
        return (
          <Typography variant="caption" sx={styles.lastOccur}>
            {dayString}
            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            {timeString}
          </Typography>
        );
      },
    },
    {
      title: "Actions",
      field: "actions",
      align: "left",
      render: (row: any) => {
        const ticketURL =
          row?.instanceDetails?.individualTicketInfo?.status !== "Done"
            ? row?.instanceDetails?.individualTicketInfo?.URL
            : null;
        const isSOC = row?.actionType === socActionType;
        return (
          <Box sx={styles.tableCellLast}>
            <TextButton
              onClick={(event) => {
                event.stopPropagation();
                handleActionClick(isSOC, row, ticketURL);
              }}
              disabled={
                //row?.instanceDetails?.status === "resolved" &&
                //!isSOC &&
                //!ticketURL
                true
              }
              startIcon={
                isSOC ? (
                  <img src={SlackIcon} alt="slack" />
                ) : (
                  <img src={JiraIcon} alt="jira" />
                )
              }
              sx={styles.tableCellAction}
            >
              {isSOC ? "Notify" : ticketURL ? "View Ticket" : "Create Ticket"}
            </TextButton>

            {/* commenting it for future requirements, for now it is not needed as we can't any action on data */}
            {/* <Divider
          orientation="vertical"
          flexItem
          sx={styles.tableActionDivider}
        />
        <MoreVertIcon sx={styles.moreIcon} /> */}
          </Box>
        );
      },
    },
  ];

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: any = policyVoilationData?.map((n: Issue) => n.id);

      setSelectedIssues(newSelected);
      return;
    }
    setSelectedIssues([]);
  };

  const isSelected = (id: any) => policyVoilationData.indexOf(id) !== -1;

  const handleClick = (id: any) => {
    const selectedIndex = selectedIssues.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIssues, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIssues.slice(1));
    } else if (selectedIndex === selectedIssues.length - 1) {
      newSelected = newSelected.concat(selectedIssues.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIssues.slice(0, selectedIndex),
        selectedIssues.slice(selectedIndex + 1)
      );
    }
    setSelectedIssues(newSelected);
  };

  const handleRowClick = (item: any) => {
    //const policyViolationId = policyData?.find(
    //  (policy) => policy?.policyId === item?.policyId
    //)?.id;
    //sessionStorage.setItem("selected_bar", JSON.stringify(item));
    //const navigateTo = `${POLICY_DETAILS}${policyViolationId}/${item?.id}`;
    //history.push(navigateTo);
  };

  const handleSort = (field: string | undefined) => {
    if (field) {
      const filterExists = sortField.find(
        (fieldItem) => fieldItem.field === field
      );
      if (filterExists) {
        if (filterExists?.order === "asc") {
          setSortField([{ field, order: "desc" }]);
        } else {
          setSortField([{ field, order: "asc" }]);
        }
      } else {
        setSortField([{ field, order: "desc" }]);
      }
    }
    //if (filterExists) {
    //  const remainingFilters = sortField.filter(
    //    (fields) => fields.field !== field
    //  );
    //  let newFilter = { field: field, order: "asc" };
    //  if (filterExists.order === "asc") {
    //    newFilter = { field: field, order: "desc" };
    //  }
    //  setSortField([newFilter, ...remainingFilters]);
    //} else {
    //  setSortField([...sortField, { field: field, order: "desc" }]);
    //}
  };

  const showAscIcon = (field: string) => {
    const filterExists = sortField.find(
      (fieldItem) => fieldItem.field === field
    );
    return !(field === filterExists?.field && filterExists.order === "desc");
  };

  const createJiraTicket = () => {
    getJiraTicket({
      policyViolationId: requestPayload?.policyViolationId,
      datastoreId: requestPayload?.datastoreId,
    });
  };

  const notifyOnSlack = () => {
    notifyOnSlackBulkAction({
      policyViolationId: requestPayload?.policyViolationId,
      datastoreId: requestPayload?.datastoreId,
    });
  };

  useEffect(() => {
    if (singleJiraTicket?.ticketName) {
      setTicketModal(true);
    } else if (singleJiraTicket?.ticketIntegration === false) {
      Toaster(TOASTER_TYPES.ERROR, "Please add Slack Integration");
    }
  }, [singleJiraTicket]);

  useEffect(() => {
    if (
      requestPayload?.datastoreId &&
      requestPayload?.policyViolationId &&
      requestPayload?.datastoreId !== "" &&
      requestPayload?.policyViolationId !== ""
    ) {
      if (requestPayload.isSOC) {
        if (integrations?.slack?.channelId) {
          notifyOnSlack();
        } else {
          Toaster(TOASTER_TYPES.ERROR, "Please add Slack Integration");
        }
      } else {
        if (integrations?.jira?.id) {
          if (requestPayload?.url) {
            window.open(requestPayload?.url);
          } else {
            getJiraTicket({
              datastoreId: requestPayload?.datastoreId,
              policyViolationId: requestPayload?.policyViolationId,
            });
          }
        } else {
          Toaster(TOASTER_TYPES.ERROR, "Please add Jira Integration");
        }
      }
    }
  }, [requestPayload]);

  /**
   * Sets data from clicked row into request payload state variable
   * @param isSOC
   * @param row
   */
  const handleActionClick = (isSOC, row, ticketURL) => {
    const policyViolationId = policyData?.find(
      (policy) => policy?.policyId === row?.policyId
    )?.id;
    setRequestPayload({
      datastoreId: row?.dsId,
      policyViolationId,
      isSOC,
      url: ticketURL,
    });
  };

  useEffect(() => {
    const aiCategory = policyCategories.find(
      (category) => category?.type === selectedPolicy?.policyType
    )?.aiCategory;
    setTableCols(
      aiCategory === "framework" ? frameworkTableCols : llmTableCols
    );
  }, [selectedPolicy?.id]);

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* commenting it for future requirements, for now it is not needed as we don't have any bulk action */}
            {/*<TableCell padding="checkbox" sx={styles.tableHead}>
              <Checkbox
                size="small"
                color="primary"
                indeterminate={
                  selectedIssues?.length > 0 &&
                  selectedIssues?.length < policyVoilationData?.length
                }
                checked={
                  policyVoilationData?.length > 0 &&
                  selectedIssues?.length === policyVoilationData?.length
                }
                onChange={onSelectAllClick}
              />
            </TableCell>*/}
            {tableCols.map((col, index) => {
              return (
                <TableCell
                  key={index}
                  align={col?.align ? (col.align as TableAlign) : "left"}
                >
                  {col.title}
                  {col?.sortField && (
                    <TextButton
                      sx={styles.sortButton}
                      onClick={() => handleSort(col?.sortField)}
                    >
                      {showAscIcon(col?.sortField) ? (
                        <ArrowUpwardRoundedIcon
                          sx={styles.sortIcon(
                            sortField.find(
                              (fieldItem) => fieldItem.field === col?.sortField
                            )
                          )}
                        />
                      ) : (
                        <ArrowDownwardRoundedIcon
                          sx={styles.sortIcon(
                            sortField.find(
                              (fieldItem) => fieldItem.field === col?.sortField
                            )
                          )}
                        />
                      )}
                    </TextButton>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {!isFetchingPolicyViolations && (
          <TableBody>
            {policyVoilationData?.map((row: any, index: number) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  key={index}
                  selected={isItemSelected}
                  onClick={() => handleRowClick(row)}
                  noHover={true}
                >
                  {/* commenting it for future requirements, for now it is not needed as we don't any bulk action */}
                  {/* <TableCell padding="checkbox" sx={styles.tableCell}>
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={isItemSelected}
                        onChange={() => handleClick(row.id)}
                      />
                    </TableCell> */}
                  {tableCols.map((col, index) => {
                    return (
                      <TableCell
                        key={index}
                        component="td"
                        scope="row"
                        align={
                          col?.align
                            ? (col.align as
                                | "left"
                                | "center"
                                | "right"
                                | "justify"
                                | "inherit"
                                | undefined)
                            : "left"
                        }
                      >
                        {col?.render ? (
                          col.render(row)
                        ) : (
                          <Typography variant="caption">
                            {row[col?.field || 0]}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>

      {!isFetchingPolicyViolations && policyViolations?.data?.length > 0 && (
        <Pagination
          rowsPerPageOptions={[10]}
          disabled={policyViolations?.total_pages <= 1}
          count={policyViolations?.total_pages}
          siblingCount={0}
          page={pageNumber}
          onChange={(e, value: number) => setPageNumber(value)}
        />
      )}

      {isFetchingPolicyViolations && <Loader height="340px" />}
      {!isFetchingPolicyViolations && policyVoilationData?.length < 1 && (
        <NoData
          customText={
            searchText !== ""
              ? `No search results containing ${searchText} found`
              : "No results found"
          }
        />
      )}
      {ticketModal && !isFetchingSingleJiraTicket && (
        <DatastoreTicketModal
          type="datastore"
          ticketDetail={singleJiraTicket}
          refreshDatastores={getPolicyViolationInstances}
          datastoreId={requestPayload?.datastoreId}
          getBulkTicketDetail={() =>
            getJiraTicket({
              policyViolationId: requestPayload?.policyViolationId,
              datastoreId: requestPayload?.datastoreId,
            })
          }
          policyViolationId={requestPayload?.policyViolationId}
          handleModal={() => {
            setTicketModal(false);
            setSingleJiraTicket({});
            setRequestPayload({
              datastoreId: "",
              policyViolationId: "",
              isSOC: false,
              url: "",
            });
          }}
        />
      )}
    </TableContainer>
  );
};

export default IssuesTable;
