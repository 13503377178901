// No Search_illustration

import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";

const NoData = ({ customText }: { customText: string }) => {
  const theme = useTheme();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: theme.spacing(2),
      width: "100%",
      height: "100%",
    },
    noDataMsg: {
      fontSize: "13px",
      color: theme.palette.surface60.main,
    },
  };
  return (
    <Box sx={styles.container}>
      <img src={NoSearchIcon} alt="no data" />
      <Typography sx={styles.noDataMsg}>{customText}</Typography>
    </Box>
  );
};

export default NoData;
