import { useTheme } from "@mui/material";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import CardWithAction from "./CardWithAction";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import {
  convertUTCToLocal,
  getDifferenceInDays,
  getFirstNValue,
  getJoinedString,
  getLoaderIcon,
} from "../../utils/commonUtils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLoaderListSlice } from "../../redux/slices/dataLoaderSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import NoData from "../common/NoData";
import Loader from "../common/Loader";
import { useHistory } from "react-router-dom";
import {
  ALL_DATA_LOADERS_PAGE,
  DATA_LOADER_DETAILS,
} from "../../routes/constants";
import { ListLoaderAPI } from "./APIResponseType";
import Tooltip from "../common/Tooltip";

const LOADER_RETRIEVAL_COUNT = 5;

const DataLoaderCard = () => {
  const [loaders, setLoaders] = useState<ListLoaderAPI[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const theme = useTheme();
  const styles = {
    loaderType: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    noDataCell: {
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  };

  const fetchLoaderList = async () => {
    setIsFetching(true);
    const payload = {
      customerId: getCustomerId(),
      count: LOADER_RETRIEVAL_COUNT,
    };
    const response = await dispatch(fetchLoaderListSlice(payload));
    if (response?.payload) {
      setLoaders(response.payload);
    } else {
      setLoaders([]);
    }
    setIsFetching(false);
  };

  const redirectToDataLoaderPage = (loaderID: string) => {
    history.push(DATA_LOADER_DETAILS + loaderID);
  };

  const redirectToAllDataLoaders = (tab) => {
    history.push(ALL_DATA_LOADERS_PAGE);
  };

  const cardData = useMemo(
    () => ({
      "data-loaders": {
        title: "Data Loaders",
        children: (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Labels</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching ? (
                  loaders?.length ? (
                    loaders?.map((loader: ListLoaderAPI) => {
                      const updatedAtDate = loader.last_updated
                        ? convertUTCToLocal(loader.last_updated).toString()
                        : null;
                      return (
                        <TableRow
                          onClick={() => redirectToDataLoaderPage(loader.id)}
                        >
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {loader.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={styles.loaderType}>
                              <img
                                src={getLoaderIcon(loader.type)}
                                alt={loader.type}
                                width="20px"
                              />
                              <Typography
                                variant="caption"
                                color={theme.palette.surface80.main}
                              >
                                {capitalizeFirstLetter(loader.type)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={getJoinedString(loader?.labels, ", ")}
                            >
                              <div>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.surface80.main}
                                >
                                  {getFirstNValue(loader.labels, 1)}
                                </Typography>
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {updatedAtDate
                                ? getDifferenceInDays(
                                    new Date(),
                                    new Date(updatedAtDate)
                                  )
                                : "-"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableCell colSpan={4} sx={styles.noDataCell}>
                      <NoData customText="No data found" />
                    </TableCell>
                  )
                ) : (
                  <TableCell colSpan={4} sx={styles.noDataCell}>
                    <Loader height="150px" />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ),
      },
    }),
    [loaders]
  );

  useEffect(() => {
    fetchLoaderList();
  }, []);

  return (
    <Box>
      <CardWithAction
        cardData={cardData}
        buttonTitle="View All"
        onClick={redirectToAllDataLoaders}
      />
    </Box>
  );
};

export default DataLoaderCard;
