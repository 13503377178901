import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BarRect from "../common/BarRect";
import Box from "../common/Box";
import Typography from "../common/Typography";
import CardWithAction from "./CardWithAction";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import {
  fetchUserAccessDataSlice,
  fetchDocAccessDataSlice,
} from "../../redux/slices/dashboardSlice";
import { CardDataObj } from "./type";
import { getSumOfValues } from "../../utils/commonUtils";
import NoData from "../common/NoData";
import { useHistory } from "react-router-dom";
import { ALL_USERS_PAGE } from "../../routes/constants";

const FETCH_DATA_LIMIT = 5;

const TabData = ({ data, cardText }) => {
  const dataKeys = data ? Object.keys(data) : [];
  const theme = useTheme();
  const styles = {
    box: {
      height: "100%",
    },
    barContainer: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  };

  return (
    <Box sx={styles.box}>
      {dataKeys && dataKeys?.length ? (
        <>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.barContainer}
          >
            {cardText}
          </Typography>
          <Box>
            {dataKeys.map((finding, index) => {
              return (
                <BarRect
                  barLabel={finding}
                  barValue={data[finding] || 0}
                  totalValue={getSumOfValues(Object.values(data!) || [])}
                  barFaceColor={theme.palette.pictonBlue.main}
                  barBackColor={theme.palette.surface20.main}
                  isLastBar={dataKeys.length - 1 === index}
                  index={index}
                  xLabel="Number of Retrievals"
                  onClick={() => {}}
                />
              );
            })}
          </Box>
        </>
      ) : (
        <NoData customText="No Findings Found" />
      )}
    </Box>
  );
};

const UserAccessCard = () => {
  const [cardData, setCardData] = useState<CardDataObj | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const styles = {
    barContainer: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  };

  const fetchAccessData = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      count: FETCH_DATA_LIMIT,
    };
    const userRes = await dispatch(fetchUserAccessDataSlice(payload));
    const docRes = await dispatch(fetchDocAccessDataSlice(payload));

    const userAccessData = userRes && userRes.payload ? userRes.payload : [];
    const docAccessData = docRes && docRes.payload ? docRes.payload : [];
    const cardData = {
      users: {
        title: "Users",
        children: (
          <TabData data={userAccessData} cardText="Top 5 Users By Retrievals" />
        ),
      },
      "documents-accessed": {
        title: "Documents Accessed",
        children: (
          <TabData
            data={docAccessData}
            cardText="Top 5 Most Accessed Documents"
          />
        ),
      },
    };

    setCardData(cardData);
    setIsLoading(false);
  };

  const redirecToAllUsersPage = (tab) => {
    history.push(`${ALL_USERS_PAGE}?tab=${tab}`);
  };

  useEffect(() => {
    fetchAccessData();
  }, []);

  return (
    <Box>
      <CardWithAction
        cardData={cardData}
        buttonTitle="View All"
        onClick={redirecToAllUsersPage}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default UserAccessCard;
