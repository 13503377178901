// Create a .env file and keep env variables there

// const LOCAL_IP = "https://everest.dev.cnox.io";

const EVEREST_DEV_URL = "https://everest-dev.k8stest.smartorbis.com";
// const DENAL_DEV_URL = "https://denali.dev.cnox.io";
const DENAL_DEV_URL = "https://denali-dev.k8stest.smartorbis.com";

export const EVEREST_URL = process.env.REACT_APP_EVEREST_URL || EVEREST_DEV_URL;
export const DENATLI_URL = process.env.REACT_APP_DENATLI_URL || DENAL_DEV_URL;
export const EULA_URL = process.env.REACT_APP_EULA_URL || "#";
export const TIMEOUT_IN_MILLISECONDS =
  process.env.REACT_APP_TIMEOUT_IN_MILLISECONDS || 1800000;
export const REACT_APP_DATASTORE_API =
  process.env.REACT_APP_DATASTORE_API || "MONGODB";
export const REACT_APP_SECURITYISSUES_API =
  process.env.REACT_APP_SECURITYISSUES_API || "MONGODB";
export const REACT_APP_ASSETS_API =
  process.env.REACT_APP_ASSETS_API || "MONGODB";
export const REACT_APP_ISSUES_DETAILS_PAGE =
  process.env.REACT_APP_ISSUES_DETAILS_PAGE || "SEPARATED";
export const REACT_APP_SHOW_WIZ_INTEGRATION =
  process.env.REACT_APP_SHOW_WIZ_INTEGRATION || "NO";

export const ACCOUNT_TYPES = {
  AWS: "aws",
  GCP: "gcp",
  AZURE: "azure",
  ORACLE_CLOUD: "oracleCloud",
};

export const FREQUENCY = {
  DEFAULT: "30m",
  CUSTOM: {
    FRQ_12_HR: "12hr",
    FRQ_24_HR: "24hr",
    FRQ_2_DAYS: "2d",
    FRQ_5_DAYS: "5d",
  },
};

export const LOADER_CONFIG = {
  type: "ball-grid-pulse",
  color: "#6c83bb",
};

// Wizard steps
export const WIZARD_STEPS = {
  SELECT_ACCOUNT: 1,
  ACCOUNT_DETAILS: 2,
  VERIFICATION_ERROR: 3,
};

// Wizard steps
export const REPORT_WIZARD_STEPS = {
  REPORT_DETAILS: 1,
  SELECT_CHAPTERS: 2,
  SCHEDULE_REPORT: 3,
};

export const DATASTORE_TYPES = {
  SHADOW: "shadow",
  MANAGED: "managed",
  SELF_MANAGED: "self-managed",
};

export const COLOR_PALETTE = {
  textInfo: "#17a2b8",
  textWarning: "#ffc107",
  textDanger: "#dc3545",
  buttonDark: "#191c2e",
};
