import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import Bar from './Bar';
import Box from './Box';
import Tooltip from './Tooltip';
import Typography from './Typography';

export interface Props {
  barLabel: string;
  barValue: number;
  totalValue: number;
  barFaceColor: string;
  barBackColor?: string;
  isLastBar?: boolean;
  index?: number;
  xLabel: string;
  onClick?: () => void;
  type?: string;
  selected?: boolean;
  id?: number;
}

const BarRect = ({
  barLabel,
  barValue,
  totalValue,
  barFaceColor = '',
  barBackColor = '',
  isLastBar = false,
  index,
  xLabel,
  onClick = () => {},
  type,
  selected = false,
  id,
}: Props) => {
  const theme = useTheme();
  const [rectWidth, setRectWidth] = useState(0);

  useEffect(() => {
    setRectWidth((barValue / totalValue) * 100);
  });

  const styles = {
    barRectContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '35% 60%',
      columnGap: theme.spacing(2),
      // gap: theme.spacing(3.5),
      // alignItems: 'center',
      // justifyContent: 'center',
      paddingX: theme.spacing(2),
      paddingY: theme.spacing(1.5),
      cursor: 'pointer',
      marginBottom: isLastBar ? theme.spacing(5) : 0,
      backgroundColor: selected ? theme.palette.surface0.main : null,
      borderLeft: selected
        ? `1px solid ${theme.palette.primaryBlue.main}`
        : null,
      '&:hover': {
        backgroundColor: theme.palette.surface0.main,
      },
    },
    barLabelStyle: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '100%',
    },
    barRect: {
      display: 'flex',
      gap: theme.spacing(0.5),
      width: '100%',
      alignItems: 'center',
      justifyContent: 'end',
    },
    barValue: {
      width: '10%',
    },
    labelContainer: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  return (
    <Box sx={{ ...styles.barRectContainer }} onClick={onClick}>
      <Tooltip title={barLabel}>
        <div style={{ ...styles.barLabelStyle, whiteSpace: 'nowrap' }}>
          <Typography
            variant="caption"
            color={theme.palette.surface60.main}
            id={selected ? 'item-selected' : ''}
            sx={styles.barLabelStyle}
          >
            {barLabel}
          </Typography>
        </div>
      </Tooltip>
      <Box sx={styles.barRect}>
        <Typography
          variant="caption"
          color={theme.palette.surface80.main}
          sx={styles.barValue}
        >
          {barValue}
        </Typography>
        <Bar
          isLastBar={isLastBar}
          xLabel={xLabel}
          barBackColor={barBackColor}
          barFaceColor={barFaceColor}
          rectWidth={rectWidth}
          index={index}
          totalValue={totalValue}
          barLabel={barLabel}
          barValue={barValue}
          type={type}
        />
      </Box>
    </Box>
  );
};

export default BarRect;
