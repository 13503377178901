import { useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { BarData, TabData } from "../../types/type";
import Box from "../common/Box";
import Loader from "../common/Loader";
import BarChartCard from "./BarChartCard";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  fetchPolicyViolationFiltersSlice,
  fetchAIPolicyViolationFiltersSlice,
  fetchPolicyViolationsSlice,
  fetchLLMPolicyViolationsSlice,
} from "../../redux/slices/dashboardSlice";
import { useDispatch } from "react-redux";

const PolicyBarChart = () => {
  const theme = useTheme();
  const {
    // isFetchingPolicyData,
    // policyData,
    // allPolicies,
    // isFetchingAllPolicies,
    // getAllPolicies,
    setSelectedPolicy,
    setOpenPolicyStoreDetailDrawer,
    setDrawerData,
  } = useContext(AppContext);
  const [extractedPolicyData, setExtractedPolicyData] = useState<{
    activeTabNumber: string;
    tabData: Array<TabData>;
  }>({
    activeTabNumber: "policy-category",
    tabData: [],
  });
  const [allPolicies, setAllPolicies] = useState<any>([]);
  const [isFetchingAllPolicies, setIsFetchingAllPolicies] = useState(false);
  const [policyCategories, setPolicyCategories] = useState([]);
  const [isFetchingPolicyCategories, setIsFetchingPolicyCategories] =
    useState(false);
  const dispatch = useDispatch();

  const listOfTabs = [
    {
      value: "policy-category",
      label: "Policy Category",
    },
    {
      value: "policy",
      label: "Policy",
    },
  ];

  const getViolatedPolicyCategory = async () => {
    setIsFetchingPolicyCategories(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const resp = await dispatch(fetchAIPolicyViolationFiltersSlice(payload));
    if (resp && resp?.payload?.length > 0) {
      setPolicyCategories(resp.payload);
    } else {
      setPolicyCategories([]);
    }
    setIsFetchingPolicyCategories(false);
  };

  const getAllPolicies = async () => {
    setIsFetchingAllPolicies(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const resp = await dispatch(fetchLLMPolicyViolationsSlice(payload));

    if (resp && resp?.payload?.length > 0) {
      setAllPolicies(resp.payload);
    } else {
      setAllPolicies([]);
    }
    setIsFetchingAllPolicies(false);
  };

  useEffect(() => {
    getViolatedPolicyCategory();
    getAllPolicies();
  }, []);

  const setOpenDetailDrawer = useCallback(
    (
      policy: {
        name: string;
        id: number;
        violations: number;
        policyType: string;
      },
      activeTab: string
    ) => {
      setDrawerData({
        tabs: listOfTabs,
        currentSelectedPolicy: policy,
        tabData: extractedPolicyData?.tabData,
        activeTab: activeTab,
      });
      setOpenPolicyStoreDetailDrawer(true);
    },
    [extractedPolicyData, extractedPolicyData?.tabData?.length]
  );

  const openDrawerAndSetPolicy = useCallback(
    (
      policy: {
        name: string;
        id: number;
        violations: number;
        policyType: string;
      },
      activeTab: string
    ) => {
      setDrawerData({
        tabs: listOfTabs,
        currentSelectedPolicy: policy,
        tabData: extractedPolicyData?.tabData,
        activeTab: activeTab,
      });
      setOpenPolicyStoreDetailDrawer(true);
      setSelectedPolicy(
        allPolicies.find((policyItem) => policyItem?.id === policy?.id)
      );
    },
    [extractedPolicyData, extractedPolicyData?.tabData?.length]
  );

  const getCategoryCount = () => {
    let dataWithCount: Array<{
      name: string;
      type: string;
      violationCount: number;
    }> = [];
    policyCategories?.map((item: any) => {
      let count: number = 0;
      allPolicies?.map((val: any) =>
        item?.type === val?.policyType
          ? (count = count + val?.violationCount)
          : 0
      );
      dataWithCount.push({ ...item, violationCount: count });
    });
    return dataWithCount;
  };

  useEffect(() => {
    if (allPolicies?.length > 0 || policyCategories?.length > 0) {
      const categoryDataWithDsCount = getCategoryCount();
      const policyCategoryTotalValue = Math.max(
        ...categoryDataWithDsCount
          ?.sort((a, b) => b.violationCount - a.violationCount)
          ?.map((item: { violationCount: number }) => item.violationCount)
      );
      const policyTotalValue = Math.max(
        ...allPolicies.map(
          (item: { violationCount: number }) => item.violationCount
        )
      );
      const topSevenPolicyData: any = [...allPolicies]
        ?.sort((a, b) => b?.violationCount - a?.violationCount)
        ?.slice(0, 7);
      const barCardsData: {
        activeTabNumber: string;
        tabData: Array<TabData>;
      } = {
        activeTabNumber: "policy-category",
        tabData: [
          {
            tabIndex: "policy-category",
            chartTitle: `${policyCategories?.length} Most Violated Policy Categories`,
            xAxisLabel: "# of Violations",
            barData: categoryDataWithDsCount?.map(
              (
                policy: { name: string; type: string; violationCount: number },
                index: number
              ) => ({
                barLabel: policy?.name,
                barValue: policy?.violationCount,
                totalValue: policyCategoryTotalValue,
                barFaceColor: theme.palette.lightBlue.main,
                barBackColor: theme.palette.surface20.main,
                id: index,
                policyType: policy?.type,
              })
            ),
          },
          {
            tabIndex: "policy",
            chartTitle: `${topSevenPolicyData?.length} Most Violated Policies`,
            xAxisLabel: "# of Violations",
            barData: topSevenPolicyData?.map(
              (policy: {
                title: string;
                description: string;
                violationCount: number;
                policyId: string;
              }) => ({
                barLabel: policy?.title?.replace(".", ""),
                barValue: policy?.violationCount,
                totalValue: policyTotalValue,
                barFaceColor: theme.palette.lightBlue.main,
                barBackColor: theme.palette.surface20.main,
                id: policy.policyId,
              })
            ),
          },
        ],
      };
      setExtractedPolicyData(barCardsData);
    }
  }, [policyCategories, allPolicies]);

  useEffect(() => {
    if (
      extractedPolicyData?.tabData?.length > 0 &&
      extractedPolicyData?.tabData[0]?.barData?.length > 0 &&
      !extractedPolicyData?.tabData[0]?.barData[0]?.onClick
    ) {
      setExtractedPolicyData((old: any) => {
        old.tabData?.forEach((tab: TabData, index: string) => {
          tab?.barData?.forEach((d: BarData, i: number) => {
            d.onClick = () => {
              tab?.tabIndex === "policy-category"
                ? setOpenDetailDrawer(
                    {
                      name: d.barLabel,
                      id: i,
                      violations: d.barValue,
                      policyType: d?.policyType || "",
                    },
                    tab?.tabIndex
                  )
                : openDrawerAndSetPolicy(
                    {
                      name: d.barLabel,
                      id: d?.id,
                      violations: d.barValue,
                      policyType: d?.policyType || "",
                    },
                    tab?.tabIndex
                  );
            };
          });
        });
        return { ...old };
      });
    }
  }, [extractedPolicyData]);

  const styles = {
    barCard: {
      width: "100%",
      paddingY: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
  };

  return (
    <Box sx={styles.barCard}>
      {isFetchingPolicyCategories || isFetchingAllPolicies ? (
        <Loader height="300px" />
      ) : (
        <BarChartCard
          listOfTabs={listOfTabs}
          activeTabNumber={extractedPolicyData?.activeTabNumber}
          tabData={extractedPolicyData?.tabData}
          openDrawer={true}
          buttonText="Details"
          showButton={true}
        />
      )}
    </Box>
  );
};

export default PolicyBarChart;
