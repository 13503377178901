import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteApp,
  fetchAppData,
  fetchAppOverview,
  fetchAppRetreivalFindings,
  fetchAppRetrievals,
  fetchAppsList,
  setAppDescription,
  setAppPolicy,
} from "../apis/appAPI";

const initialState = {};

export const fetchAppDetailsSlice = createAsyncThunk(
  "app/details",
  async (params: { customerId: string; appId: string }) => {
    const response = await fetchAppData(params);
    return response.data;
  }
);

export const fetchAppOverviewSlice = createAsyncThunk(
  "app/overview",
  async (params: { customerId: string; appId: string }) => {
    const response = await fetchAppOverview(params);
    return response.data;
  }
);

export const fetchAppRetreivalsSlice = createAsyncThunk(
  "app/retrievals",
  async (params: { customerId: string; appId: string }) => {
    const response = await fetchAppRetrievals(params);
    return response.data;
  }
);

export const fetchAppsListSlice = createAsyncThunk(
  "apps/list",
  async (params: { customerId: string; count?: number }) => {
    const response = await fetchAppsList(params);
    return response.data;
  }
);

export const deleteAppSlice = createAsyncThunk(
  "app/delete",
  async (params: { customerId: string; appId: string }) => {
    const response = await deleteApp(params);
    return response.data;
  }
);

export const fetchAppRetreivalFindingsSlice = createAsyncThunk(
  "app/findings",
  async (params: any) => {
    const response = await fetchAppRetreivalFindings(params);
    return response.data;
  }
);

export const setAppDescriptionSlice = createAsyncThunk(
  "app/description",
  async (params: { customerId: string; id: string; description: string }) => {
    const response = await setAppDescription(params);
    return response.data;
  }
);

export const setAppPolicySlice = createAsyncThunk(
  "app/policy",
  async (params: { customerId: string; id: string; policy: JSON }) => {
    const response = await setAppPolicy(params);
    return response.data;
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default appSlice.reducer;
