import { useTheme } from "@mui/material";
import BarRect from "./BarRect";
import Box from "./Box";
import Typography from "./Typography";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";
import { useHistory } from "react-router-dom";
import { datastoreFilter } from "../constants/constants";
import { DATASTORE } from "../../routes/constants";

export interface BarChartProps {
  chartTitle?: string;
  barData: {
    barLabel: string;
    barValue: number;
    totalValue: number;
    barFaceColor: string;
    barBackColor: string;
    id: number;
  }[];
  xAxisLabel: string;
  type?: string;
  selected?: number;
  activeTab?: string;
}

const BarChart = ({
  chartTitle,
  barData = [],
  xAxisLabel,
  type,
  selected,
  activeTab,
}: BarChartProps) => {
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    barContainer: {
      display: "flex",
      gap: theme.spacing(1),
      flexDirection: "column",
    },
    fontLightWithSpacing: {
      fontWeight: theme.typography.fontWeightLight,
      paddingX: theme.spacing(2),
    },
    noDataContainer: {
      width: "100%",
      height: "250px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataMsg: {
      fontSize: "13px",
    },
  };

  const redirectToPage = (barLabel) => {
    const tabName = datastoreFilter.find(
      (data) => data.value === activeTab
    )?.filter;
    history.push(`${DATASTORE}?tab=${tabName}&search=${barLabel}`);
  };

  return (
    <Box sx={styles.barContainer}>
      {barData.length > 0 ? (
        <>
          {chartTitle && (
            <Typography variant="caption" sx={styles.fontLightWithSpacing}>
              {chartTitle}
            </Typography>
          )}
          <Box>
            {barData.map(
              (
                barItem: {
                  barLabel: string;
                  barValue: number;
                  totalValue: number;
                  barFaceColor: string;
                  barBackColor: string;
                  onClick?: () => void;
                  id: number;
                },
                index: number
              ) => (
                <BarRect
                  key={index}
                  isLastBar={barData.length - 1 === index}
                  index={index}
                  xLabel={xAxisLabel}
                  type={type}
                  selected={barItem.id === selected}
                  onClick={() => redirectToPage(barItem?.barLabel)}
                  {...barItem}
                />
              )
            )}
          </Box>
        </>
      ) : (
        <Box sx={styles.noDataContainer}>
          <img src={NoSearchIcon} alt="no data" width="100px" />
          <Typography
            color={theme.palette.surface60.main}
            sx={styles.noDataMsg}
          >
            No Data Exists
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BarChart;
