export const dataStoreTypes = [
  {
    label: "All",
    filter: "",
    value: "all",
  },
  {
    label: "Managed",
    filter: "managed",
    value: "managed",
  },
  {
    label: "AI",
    filter: "shadow",
    value: "ai",
  },
  {
    label: "Shadow",
    filter: "shadow",
    value: "shadow",
  },
];

export const aiDataStoreTabs = [
  {
    label: "All LLMs",
    filter: "",
    value: "all",
  },
  {
    label: "Deployed",
    filter: "llm-deployed",
    value: "deployed",
  },
  {
    label: "Under Finetuning",
    filter: "llm-under-fine-tuning",
    value: "under_fine_tuning",
  },
];

export const datastoreFilter = [
  {
    filter: "",
    value: "all",
  },
  {
    filter: "MANAGED",
    value: "managed",
  },
  {
    filter: "SHADOW_AI",
    value: "ai",
  },
  {
    filter: "SHADOW",
    value: "shadow",
  },
];

export const non_SOC = [
  "data-residency",
  "encryption",
  "logging",
  "public-access",
];

export const SOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Notified",
    value: "notified",
  },
  {
    label: "Archived",
    value: "archived",
  },
];

export const NonSOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ticketed",
    value: "ticketed",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const socActionType = "notify/archive";
export const nonSocActionType = "ticket/accept";

export const langchainData = [
  {
    name: "Langchain 1",
    version: "V0.0284",
  },
];

export const accountType = {
  AI: "Gen-AI Security",
  Data: "Data Security",
};

export const scanTypeAI = "ai-scan";
export const aiTypeGen = "Gen-AI";
export const aiTypeLangchain = "Langchain-AI";

export const promptTimestampFilter = [
  { value: "week", label: "Last 7 Days" },
  { value: "day", label: "Last 1 day" },
  { value: "hour", label: "Last 1 hour" },
];

export const promptSession = {
  context: "sensitiveContextCount",
  response: "sensitiveResponseCount",
  prompt: "sensitivePromptCount",
};

export const entityPolicy = ["apSensitiveIdentifier", "apRestrictedEntities"];
export const topicPolicy = ["apRestrictedTopic"];

export const sensitiveInfoTags = {
  social_security_numbers: "SSN",
  credit_cards: "CC",
  drivers_licenses: "DL",
  mobile_numbers: "MN",
};

export const SNIPPET_COUNT_KEY = "snippet_count";

export const APPLICATION_STR = "application";
export const DATA_LOADER_STR = "data-loader";
