import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Search from "../common/Search";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  getFirstNValue,
  getJoinedString,
  getLoaderIcon,
} from "../../utils/commonUtils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAppsListSlice } from "../../redux/slices/appSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { ListAppAPI } from "../dashboard-widgets/APIResponseType";
import Tooltip from "../common/Tooltip";
import NoData from "../common/NoData";
import Loader from "../common/Loader";
import { useHistory } from "react-router-dom";
import { APPLICATION_DETAILS } from "../../routes/constants";
import { Breadcrumbs, Link } from "../common/Breadcrumbs";

const AppListingPage = () => {
  const [apps, setApps] = useState<ListAppAPI[]>([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    pageHeader: {
      display: "flex",
      alignItems: "center",
    },
    searchOptions: {
      marginLeft: "auto",
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    divider: {
      height: "13px",
    },
    appName: {
      display: "flex",
      gap: theme.spacing(1),
    },
    appVersion: {
      display: "flex",
      gap: theme.spacing(1),
    },
    font13: {
      fontSize: "13px",
    },
    noDataCell: {
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
  };

  const tableCols = [
    {
      title: "Name",
      sortField: "",
      field: "appName",
      align: "",
      render: (data) => (
        <Box sx={styles.appName}>
          <img src={getLoaderIcon(data?.name)} alt="app" />
          <Box sx={styles.appVersion}>
            <Typography color={theme.palette.surface80.main} sx={styles.font13}>
              {data?.name}
            </Typography>
            <Divider orientation="vertical" sx={styles.divider} />
            <Typography color={theme.palette.surface60.main} variant="caption">
              {data?.version}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: "Policies Violated",
      sortField: "",
      field: "appName",
      align: "",
    },
    {
      title: "Retrievals",
      sortField: "",
      field: "appName",
      align: "",
    },
    {
      title: "Active Users",
      sortField: "",
      field: "appName",
      align: "",
    },
    {
      title: "Labels",
      sortField: "",
      field: "appName",
      align: "",
    },
  ];

  const fetchAllApps = async () => {
    setIsFetchingData(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchAppsListSlice(payload));
    if (response.payload) {
      setApps(response.payload);
    }
    setIsFetchingData(false);
  };

  const redirectToAppDetailsPage = (appId: string) => {
    history.push(APPLICATION_DETAILS + appId);
  };

  useEffect(() => {
    fetchAllApps();
  }, []);

  return (
    <Box sx={styles.page}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Home
        </Link>
        <Typography variant="caption" sx={styles.activeLink}>
          All Applications
        </Typography>
      </Breadcrumbs>
      <Box sx={styles.pageHeader}>
        <Typography>List of Applications</Typography>
        {/*<Box sx={styles.searchOptions}>
          <Search
            placeholder={"Search By Data Loader Name"}
            value={""}
            onChange={(e) => {}}
            onClear={() => {}}
            onKeyDown={(e) => {}}
          />
          <Divider orientation="vertical" sx={styles.divider} />
          <TextButton startIcon={<FilterListOutlinedIcon />}>
            Filters
          </TextButton>
        </Box>*/}
      </Box>
      <Box sx={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Policies Violated</TableCell>
                <TableCell align="right">Retrievals</TableCell>
                <TableCell align="right">Active Users</TableCell>
                <TableCell>Labels</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isFetchingData ? (
                apps?.length > 0 ? (
                  apps.map((app) => {
                    return (
                      <TableRow
                        onClick={() => redirectToAppDetailsPage(app.id)}
                      >
                        <TableCell>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {app.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {app?.policyViolated || "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {app?.retrievals || "0"}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {app?.active_users}
                          </Typography>
                        </TableCell>
                        <TableCell width="30%">
                          <Tooltip title={getJoinedString(app?.labels)}>
                            <div>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface80.main}
                              >
                                {getFirstNValue(app?.labels)}
                              </Typography>
                            </div>
                          </Tooltip>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell colSpan={5} sx={styles.noDataCell}>
                    <NoData customText="No data found" />
                  </TableCell>
                )
              ) : (
                <TableCell colSpan={5} sx={styles.noDataCell}>
                  <Loader height="150px" />
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AppListingPage;
