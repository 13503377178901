import { PaletteMode } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    indigo: Palette["primary"];
    lavender: Palette["primary"];
    lightPurple: Palette["primary"];
    primaryBlue: Palette["primary"];
    secondaryBlue: Palette["primary"];
    blueGrey: Palette["primary"];
    lightBlue: Palette["primary"];
    critical: Palette["primary"];
    high: Palette["primary"];
    warning: Palette["primary"];
    success: Palette["primary"];
    pictonBlue: Palette["primary"];
    lightMagenta: Palette["primary"];
    purpleStroke: Palette["primary"];
    redStroke: Palette["primary"];
    peach: Palette["primary"];
    paleBlue: Palette["primary"];
    laurelGreen: Palette["primary"];
    yellowRed: Palette["primary"];
    green: Palette["primary"];
    mistyMoss: Palette["primary"];
    surface100: Palette["primary"];
    surface90: Palette["primary"];
    surface80: Palette["primary"];
    surface70: Palette["primary"];
    surface60: Palette["primary"];
    surface50: Palette["primary"];
    surface40: Palette["primary"];
    surface30: Palette["primary"];
    surface20: Palette["primary"];
    surface10: Palette["primary"];
    surface5: Palette["primary"];
    surface0: Palette["primary"];
    surfaceMain: Palette["primary"];
    surfaceFloatingWindow: Palette["primary"];
    surfaceCard: Palette["primary"];
  }
  interface PaletteOptions {
    indigo: PaletteOptions["primary"];
    lavender: PaletteOptions["primary"];
    lightPurple: PaletteOptions["primary"];
    primaryBlue: PaletteOptions["primary"];
    secondaryBlue: PaletteOptions["primary"];
    blueGrey: PaletteOptions["primary"];
    lightBlue: PaletteOptions["primary"];
    critical: PaletteOptions["primary"];
    high: PaletteOptions["primary"];
    //warning: PaletteOptions['primary'];
    //success: PaletteOptions['primary'];
    pictonBlue: PaletteOptions["primary"];
    lightMagenta: PaletteOptions["primary"];
    purpleStroke: PaletteOptions["primary"];
    redStroke: PaletteOptions["primary"];
    peach: PaletteOptions["primary"];
    paleBlue: PaletteOptions["primary"];
    laurelGreen: PaletteOptions["primary"];
    yellowRed: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    mistyMoss: PaletteOptions["primary"];
    surface100: PaletteOptions["primary"];
    surface90: PaletteOptions["primary"];
    surface80: PaletteOptions["primary"];
    surface70: PaletteOptions["primary"];
    surface60: PaletteOptions["primary"];
    surface50: PaletteOptions["primary"];
    surface40: PaletteOptions["primary"];
    surface30: PaletteOptions["primary"];
    surface20: PaletteOptions["primary"];
    surface10: PaletteOptions["primary"];
    surface5: PaletteOptions["primary"];
    surface0: PaletteOptions["primary"];
    surfaceMain: PaletteOptions["primary"];
    surfaceFloatingWindow: PaletteOptions["primary"];
    surfaceCard: PaletteOptions["primary"];
  }
}

// Primary palette
const primaryPalette = {
  lightBlue: { main: "#92CAFF" },
  indigo: { main: "#8A4CE2" },
  lavender: { main: "#AB7FEA" },
  lightPurple: { main: "#CCB2F2" },
};

// Data visualization palette
const dataVisualisationPalette = {
  pictonBlue: { main: "#AC6AEE" },
  lightMagenta: { main: "#816DFF" },
  purpleStroke: { main: "#6C56F1" },
  redStroke: { main: "#ED483D" },
  peach: { main: "#FFBAAD" },
  paleBlue: { main: "#84C3FF" },
  laurelGreen: { main: "#B3E29D" },
  yellowRed: { main: "#FFB984" },
  green: { main: "#94EEE5" },
  mistyMoss: { main: "#BF6F80" },
};

export const lightPalette = {
  ...primaryPalette,
  ...dataVisualisationPalette,
  primaryBlue: { main: "#0074E0" },
  secondaryBlue: { main: "#5CB1FF" },
  blueGrey: { main: "#21253B" },
  critical: { main: "#F03844" },
  high: { main: "#EC6D36" },
  warning: { main: "#EBB52A" },
  success: { main: "#14BE9B" },
  surface100: { main: "#000000" },
  surface90: { main: "#02051B" },
  surface80: { main: "#191C2E" },
  surface70: { main: "#3B3E52" },
  surface60: { main: "#535874" },
  surface50: { main: "#63698B" },
  surface40: { main: "#787D9E" },
  surface30: { main: "#B5B8CA" },
  surface20: { main: "#CED0DC" },
  surface10: { main: "#E6E7ED" },
  surface5: { main: '#7B7B7D' },
  surface0: { main: "#F5F5F9" },
  surfaceMain: { main: "#FFFFFF" },
  surfaceFloatingWindow: { main: "#1F2338" },
  surfaceCard: { main: "#121523" },
  mode: "light" as PaletteMode,
};

export const darkPalette = {
  ...primaryPalette,
  ...dataVisualisationPalette,
  primaryBlue: { main: "#2195FF" },
  secondaryBlue: { main: "#5CB1FF" },
  blueGrey: { main: "#21253B" },
  critical: { main: "#FF655B" },
  high: { main: "#F88D50" },
  warning: { main: "#FBC355" },
  success: { main: "#53D7BB" },
  surface100: { main: "#FFFFFF" },
  surface90: { main: "#F5F5F9" },
  surface80: { main: "#E6E7ED" },
  surface70: { main: "#CED0DC" },
  surface60: { main: "#B5B8CA" },
  surface50: { main: "#787D9E" },
  surface40: { main: "#63698B" },
  surface30: { main: "#535874" },
  surface20: { main: "#3B3E52" },
  surface10: { main: "#191C2E" },
  surface5: { main: '#131629' },
  surface0: { main: "#02051B" },
  surfaceMain: { main: "#010102" },
  surfaceFloatingWindow: { main: "#1F2338" },
  surfaceCard: { main: "#121523" },
  mode: "dark" as PaletteMode,
};
