import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/accountSlice";
import integrationReducer from "./slices/integrationSlice";
import assetReducer from "./slices/assetsSlice";
import reportsReducer from "./slices/reportsSlice";
import dashboardReducer from "./slices/dashboardSlice";
import globalOperationReducer from "./slices/globalOperationSlice";
import dataStoreReducer from "./slices/dataStoreSlice";
import issueReducer from "./slices/issuesSlice";
import ticketReducer from "./slices/ticketSlice";
import policyReducer from "./slices/policySlice";
import llmReducer from "./slices/llmSlice";
import dataLoaderReducer from "./slices/dataLoaderSlice";
import appReducer from "./slices/appSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    integration: integrationReducer,
    asset: assetReducer,
    globalOperation: globalOperationReducer,
    report: reportsReducer,
    dashboard: dashboardReducer,
    datastore: dataStoreReducer,
    issue: issueReducer,
    ticket: ticketReducer,
    policy: policyReducer,
    llm: llmReducer,
    dataLoader: dataLoaderReducer,
    app: appReducer,
  },
});
